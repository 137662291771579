/** Note: Prettier is overly concerned with this mock data structure  */
/* eslint-disable */
const searchResultsSuccess = {
  "SearchTerm": "Italian Food",
  "Filters": [
    {
      "Title": "Fresh Creations",
      "Tag": "Category",
      "TagId": "0",
      "Count": 0
    },
    {
      "Title": "New in Stock",
      "Tag": "Category",
      "TagId": "1",
      "Count": 5
    },
    {
      "Title": "Sharpen Your Skills",
      "Tag": "Category",
      "TagId": "2",
      "Count": 0
    },
    {
      "Title": "The Market Observer",
      "Tag": "Category",
      "TagId": "3",
      "Count": 5
    },
    {
      "Title": "Roma",
      "Tag": "Brand",
      "TagId": "4",
      "Count": 5
    },
    {
      "Title": "Ascend",
      "Tag": "Brand",
      "TagId": "5",
      "Count": 0
    },
    {
      "Title": "Burst",
      "Tag": "Brand",
      "TagId": "6",
      "Count": 5
    },
    {
      "Title": "Breakfast",
      "Tag": "Course",
      "TagId": "7",
      "Count": 5
    },
    {
      "Title": "Lunch",
      "Tag": "Course",
      "TagId": "8",
      "Count": 5
    },
    {
      "Title": "Dinner",
      "Tag": "Course",
      "TagId": "9",
      "Count": 5
    },
    {
      "Title": "Appetizer",
      "Tag": "Course",
      "TagId": "10",
      "Count": 0
    },
    {
      "Title": "Italian",
      "Tag": "Cuisine",
      "TagId": "11",
      "Count": 5
    },
    {
      "Title": "American",
      "Tag": "Cuisine",
      "TagId": "12",
      "Count": 5
    },
    {
      "Title": "Chinese",
      "Tag": "Cuisine",
      "TagId": "13",
      "Count": 5
    },
    {
      "Title": "Comfort Food",
      "Tag": "Cuisine",
      "TagId": "14",
      "Count": 5
    }
  ],
  "SearchResults": [
    {
      "Tag": "Brand",
      "Title": "Ascend",
      "Summary": "<h4>Our Brands</h4><p>Consectetur doloribus a asperiores sapiente nobis? Delectus alias dolorum atque nihil quia Harum quibusdam doloribus hic quia molestias ad Eius illum facilis dolore unde non! Quaerat laborum tempore temporibus ad?</p>",
      "LinkText": "Read More",
      "Image": "/assets/images/content/brands/performancefoodservice_brands_card_ascend.png",
      "LinkUrl": "#"
    },
    {
      "Tag": "Recipes",
      "Title": "Delicious Appetizer Bowl",
      "Summary": "<h4>Recipes</h4><p>Toss red onion, peppers, and garlic with 1½ tablespoons olive oil. Transfer to parchment-lined sheet pan and roast in preheated 400°F convection oven for 20 minutes. Transfer to bowl of food processor; add fire roasted tomatoes, 2 tablespoons balsamic vinegar, basil, and ¾ teaspoon salt. Pulse mixture until chunks are broken down but salsa still has chunky texture.</p>",
      "LinkText": "Read More",
      "Image": "/assets/images/content/appetizer-bowl-delicious-1640772.jpg",
      "LinkUrl": "#"
    },
    {
      "Tag": "Recipes",
      "Title": "Why White Pastas Are So Hot Right Now",
      "Summary": "<h4>Insights</h4><p>Pasta dishes have long been found in places other than Italian restaurants. The problem, many of these pasta dishes mean overcooked spaghetti with a sauce that’s taste could be best described as “red.” Times have changed and consumers now demand something a little better. Al dente is the buzz word and sauces aren’t there to drown the pasta, but rather to gently accentuate it either alone or as the backdrop for exciting and interesting fresh ingredients. It doesn’t hurt that, whether house-made or dry packaged, pasta is inexpensive and a boon to razor thin margins. So, if Italian isn’t your thing, how do you get a great pasta dish in front of your diners?</p>",
      "LinkText": "Read More",
      "Image": "",
      "LinkUrl": "#"
    },
    {
      "Tag": "Brand",
      "Title": "Assoluti",
      "Summary": "<h4>Brands</h4><p>Basics and necessities geared to pizza and Italian restaurants with consistent quality. Choose from flours, tomatoes, pizza cheeses, Italian and blended oils, dry pasta, calamari, Italian meats and meatballs.</p>",
      "LinkText": "Read More",
      "Image": "/assets/images/content/brands/performancefoodservice_brands_card_assoluti.png",
      "LinkUrl": "#"
    },
    {
      "Tag": "Recipes",
      "Title": "Balsamic-Herb Roasted Potato Chips with Italian Salsa",
      "Summary": "<h4>Recipes</h4><p>Toss red onion, peppers, and garlic with 1½ tablespoons olive oil. Transfer to parchment-lined sheet pan and roast in preheated 400°F convection oven for 20 minutes. Transfer to bowl of food processor; add fire roasted tomatoes, 2 tablespoons balsamic vinegar, basil, and ¾ teaspoon salt. Pulse mixture until chunks are broken down but salsa still has chunky texture.</p>",
      "LinkText": "Read More",
      "Image": "/assets/images/content/appetizer-bowl-delicious-1640772.jpg",
      "LinkUrl": "#"
    },
    {
      "Tag": "Recipes",
      "Title": "Pumpkin Pasta with Italian Sausage",
      "Summary": "<h4>Recipes</h4><p>In a large deep non stick skillet over medium high heat add one tablespoon of olive oil and brown the sausage. If in casings, remove sausage from casings. Transfer sausage to a paper towel lined plate. Drain fat from skillet and return pan to stove. Add the other tablespoon of olive oil, garlic, and onion. Saute 3 to 5 minutes until onion are tender. Add bay leaf, sage, and wine to the pan. Reduce wine by half, about 2 minutes. Add stock and pumpkin and stir to combine, stirring sauce until it comes to a bubble.</p>",
      "LinkText": "Read More",
      "Image": "/assets/images/content/appetizer-bowl-delicious-1640772.jpg",
      "LinkUrl": "#"
    },
    {
      "Tag": "Recipes",
      "Title": "Balsamic-Herb Roasted Potato Chips with Italian Salsa",
      "Summary": "<h4>Recipes</h4><p>Toss red onion, peppers, and garlic with 1½ tablespoons olive oil. Transfer to parchment-lined sheet pan and roast in preheated 400°F convection oven for 20 minutes. Transfer to bowl of food processor; add fire roasted tomatoes, 2 tablespoons balsamic vinegar, basil, and ¾ teaspoon salt. Pulse mixture until chunks are broken down but salsa still has chunky texture.</p>",
      "LinkText": "Read More",
      "Image": "/assets/images/content/appetizer-bowl-delicious-1640772.jpg",
      "LinkUrl": "#"
    }
  ],
  "CurrentPage": 1,
  "TotalPages": 2,
  "TotalResults": 8
};
/* eslint-enable */

export default searchResultsSuccess;