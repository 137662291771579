// Fullpage 3.x documentation: 
// https://github.com/alvarotrigo/fullPage.js/tree/3.1.2

import $ from "jquery";
import Fullpage from "fullpage.js";
import enquire from "enquire.js";
import appConfig from "../app-config";
import ally from "ally.js";
import debounce from "lodash/debounce";
import {
  MQ_MOBILE_SMALL,
  MQ_MOBILE_WIDE_ONLY,
  MQ_TABLET,
  BRAND_BRIGHT_RED,
  SCROLL_TYPE_STICKY_LEFT_PANEL,
  SCROLL_TYPE_SCROLLING_LEFT_PANEL
} from "../constants";
import { panelContentMismatchError } from "../errors";
import { layoutUtils } from ".";

const fullpageContainerSelector = "#full-page-scroll-container";
let fullpageInstance;
let $fullpageContainer;
let $leftPanel;
let scrollType;
let mousePosition;
let keyboardControlInitialized;

/**
 * Creates an array of background colors for slides:
 * - Mobile with scrolling panel pattern: alternate white, red and white, black.
 * - Mobile with fixed panel pattern: white, then alternate red, black,
 * - Tablet and up pattern: alternate red and black
 * @param {number} numSections The number of slide sections.
 * @param {boolean} isLargeLayout
 * @returns {Array} Array of hexidecimal color values as strings.
 */
function createSectionColorArray(numSections, isLargeLayout = false) {
  const colors = Array(...Array(numSections)).map(
    (el, i) => [BRAND_BRIGHT_RED, "#000"][i % 2]
  );

  if (!isLargeLayout) {
    return scrollType === SCROLL_TYPE_SCROLLING_LEFT_PANEL
      ? colors.reduce((acc, cur) => acc.concat(["#FFF", cur]), [])
      : ["#FFF"].concat(colors);
  }
  return colors;
}

/**
 * Determines the scroll type to use based on the number of left panel
 * and main content sections. When one panel content section exists, it
 * remains sticky when the main content sections scroll. When multiple
 * panel content sections exist, they change along with the main content
 * sections.
 * @param {number} numPanelSections
 * @param {number} numMainSections
 * @return {string} The scroll type constant.
 * @throws Throws an error when number of panel and main sections is mismatched.
 */
function determineScrollType(numPanelSections, numMainSections) {
  if (numPanelSections > 1) {
    if (numPanelSections !== numMainSections) {
      throw panelContentMismatchError(numPanelSections, numMainSections);
    }
    return SCROLL_TYPE_SCROLLING_LEFT_PANEL;
  } else if (numPanelSections === 1) {
    return SCROLL_TYPE_STICKY_LEFT_PANEL;
  } else {
    throw panelContentMismatchError(numPanelSections, numMainSections);
  }
}

/**
 * Adds the down arrow to the bottom of each slide
 * @param {Array} sections A collection of fullpage slide elements
 */
function appendFpsArrows(sections) {
  sections.forEach(section => {
    $(section).append(layoutUtils.getFullPageArrowMarkup());
  });
}

/**
 * Event listener for advancing the fullpage slide. It is registered
 * as a click event handler on the fullpage container itself.
 */
function moveFullPageSectionDown() {
  if(!document.querySelector('.fp-section.active.fp-completely')) return;
  fullpageInstance.moveSectionDown();
}

function moveFullPageSectionUp() {
  if(!document.querySelector('.fp-section.active.fp-completely')) return;
  fullpageInstance.moveSectionUp();
}

/**
 * Creates a new fullpage instance
 * @param {string} containerSelector The fullpage container selector.
 * @param {Array} colors Array of hexidecimal color values as strings.
 * @returns {Object} The fullpage instance.
 */
function initFullpage(containerSelector, colors, config = {}) {
  fullpageInstance = new Fullpage(
    containerSelector,
    Object.assign(
      {
        licenseKey: appConfig.fullpageLicenseKey,
        navigation: true,
        sectionsColor: colors
      },
      config,
      {
        afterRender() {
          $(".main-layout").addClass("fade-in");
          $(".scrolling-left-panel").addClass("left-panel-ready");
          if (typeof config.afterRender === "function") {
            config.afterRender();
          }
        },
        afterLoad() {
          // Focus first focusable element in slide after load.
          const leftPanels = document.querySelector('.left-rail .left-panel-content');
          if(leftPanels.length > 1) {
          } else {
          }
        }
      }
    )
  );
  appendFpsArrows(
    $(fullpageContainerSelector)
      .find(".section")
      .toArray()
  );
}

const getFirstTabbableActiveLeftPanelEl = () => ally.query.firstTabbable({context: '.left-panel-content.active'});
const getLastTabbableActiveLeftPanelEl = () => ally.query.tabbable({context: '.left-panel-content.active'}).pop();
const getFirstTabbableActiveSlideEl = () => ally.query.tabbable({context: '.fp-section.active'}).filter(el => !el.closest('.swiper-slide[inert]'))[0];
const getLastTabbableActiveSlideEl = () => ally.query.tabbable({context: '.fp-section.active'}).pop();
const isLastActiveLeftPanelElFocused = () => ally.get.activeElement() === getLastTabbableActiveLeftPanelEl();
const isFirstActiveLeftPanelElFocused = () => ally.get.activeElement() === getFirstTabbableActiveLeftPanelEl();
const isLastActiveSlideElFocused = () => ally.get.activeElement() === getLastTabbableActiveSlideEl();
const isFirstActiveSlideElFocused = () => ally.get.activeElement() === getFirstTabbableActiveSlideEl();
const hasMultipleLeftPanels = () => document.querySelectorAll('.left-rail .left-panel-content').length > 1;
const isLastSlide = () => Array.from(document.querySelectorAll('.fp-section')).reverse()[0].classList.contains('active');
const isSecondToLastSlide = () => Array.from(document.querySelectorAll('.fp-section')).reverse()[1].classList.contains('active');
const isFirstSlide = () => Array.from(document.querySelectorAll('.fp-section'))[0].classList.contains('active');

function focusFirstActiveSlideEl() {
  setTimeout(function() {
    ally.element.focus(getFirstTabbableActiveSlideEl());
  }, 10)
}

function focusLastActiveSlideEl() {
  setTimeout(function() {
    ally.element.focus(ally.query.tabbable({context: '.fp-section.active'}).pop());
  }, 10);
}

function focusFirstLeftPanelEl() {
  if(ally.query.tabbable({context: '.left-panel-content.active'}).length) {
    ally.element.focus(ally.query.firstTabbable({context: '.left-panel-content.active'}));
  }
}

function manageFocusOnSlideUp(didTransitionFromLastSlide) {
    if(didTransitionFromLastSlide) {
      // when last slide, move to first focusable section of footer
      setTimeout(function () {
        ally.element.focus(ally.query.tabbable({context: '.left-rail .social-btn-container'})[0]);
      }, 10);
    } else if(isFirstSlide() || hasMultipleLeftPanels()) {
      if(getFirstTabbableActiveSlideEl() === ally.get.activeElement()) {
        setTimeout(function () {
          focusFirstLeftPanelEl();
        }, 10);
        //e.preventDefault();
      }
    } else {
      focusFirstActiveSlideEl();
    }
}

function manageFocusOnSlideDown() {
  if(isSecondToLastSlide()) {
    // When last slide is active, shift the last slide, shift focus to button containers
    ally.element.focus(ally.query.firstTabbable({context: '.account-btn-container'}));
  } else if(hasMultipleLeftPanels()) {
    // When multiple left panels exist, shift focus to first acive item in it
    setTimeout(function () {
      focusFirstLeftPanelEl();
    }, 10);
  } else {
     focusFirstActiveSlideEl();
  }
}

function handleTabPressOnLeftPanel(isShiftPressed) {
  // Tab from left panel to slide
  if(isLastActiveLeftPanelElFocused()) {
    if(hasMultipleLeftPanels()) {
      if(isLastSlide()) {
        // If last panel, go to left rail footer
        setTimeout(function() {
          ally.element.focus(ally.query.firstTabbable({context: '.account-btn-container'}));
        }, 10);
      } else if(isShiftPressed) {
        if(isFirstActiveLeftPanelElFocused && !document.querySelectorAll('#left-panel .left-panel-content')[0].classList.contains('active')) {
          debounce(function () {
            moveFullPageSectionUp();
          }, 100)();
        }
        // if first card and first element is active  
      } else {
        // Or go to active slide.
        focusFirstActiveSlideEl();
      }
    } else {
      // otherwise go to slide 1
      fullpageInstance.silentMoveTo(1);
      setTimeout(function() {
        ally.element.focus(getFirstTabbableActiveSlideEl());
      }, 10);
    }
  }
}

function handleTabPressOnLeftRailFooter(isShiftPressed) {
  const firstTabbableEl = ally.query.firstTabbable({context: '.account-btn-container'});
  const lastTabbableEl = ally.query.tabbable({context: '.left-rail .social-btn-container'}).pop();

  // After account and social elements in left rail, footer receives focus.
  if(!isShiftPressed && lastTabbableEl === ally.get.activeElement()) {
    fullpageInstance.silentMoveTo(document.querySelectorAll('.fp-section').length);
    focusFirstActiveSlideEl();
  }

  // Before account and social elements in left rail, last slide's last tabbable element receives focus.
  if(isShiftPressed && firstTabbableEl === ally.get.activeElement()) {
    fullpageInstance.silentMoveTo(document.querySelectorAll('.fp-section').length - 1);
    focusLastActiveSlideEl();
  }
}

// Add keyboard control to full-page slide arrows.
function handleTabPressOnSlides (e) {
  // There will be no `fp-completely` class when FP is animating. 
  if(!document.querySelector('.fp-section.active.fp-completely')) {
    return false;
  };

  const activeEl = ally.get.activeElement();

  if(e.shiftKey) {
    const firstTabbableEl = getFirstTabbableActiveSlideEl();

    if(firstTabbableEl === activeEl) {
      if(Array.from(document.querySelectorAll('.fp-section'))[0].classList.contains('active')) {
        focusFirstLeftPanelEl();
        e.preventDefault();
      } else {
        debounce(function () {
          moveFullPageSectionUp();
          const didTransitionFromLastSlide = Array.from(document.querySelectorAll('.fp-section')).reverse()[1].classList.contains('active');
          //manageFocusOnSlideUp(didTransitionFromLastSlide);
        }, 100)();
      }
    }
  } else if(activeEl && activeEl.classList.contains('full-page-arrow')) {
    debounce(function () {
      moveFullPageSectionDown();
      //manageFocusOnSlideDown();
    }, 100)();
  }
};

// search, hamburger menu, or last item in hamburger menu
function handleTabPressOnLastNavEl() {
  console.log('LAST NAV EL');
  // If the active left panel does not have any focusable elements, tab flow shifts to the slides.
  if(ally.query.tabbable({context:'.left-panel-content.active',strategy:'strict'}).length === 0) {
    if(hasMultipleLeftPanels()) {
      ally.element.focus(getFirstTabbableActiveSlideEl());
    } else {
      fullpageInstance.silentMoveTo(1);
      ally.element.focus(getFirstTabbableActiveSlideEl());
    }
  }
}


function destroyFullpage(layout) {
  $(fullpageContainerSelector)
    .find(".full-page-arrow")
    .remove();
  if (fullpageInstance && fullpageInstance.destroy) {
    fullpageInstance.destroy("all");
  }

  // Remove dynamically created content sections from the main content area
  // (added for medium layout).
  $fullpageContainer.find(".section-dynamic").remove();

  // Remove active class from currently visible left panel content
  // (added for large layout).
  $leftPanel.find(".left-panel-content").removeClass("active");

  // Remove no-scroll class (added for small layout).
  $fullpageContainer.find(".no-scroll").removeClass("no-scroll");
}

/**
 * Determines if the slide should change on scroll. Returns `false` if
 * the scroll was initiated on top of the expanded nav.
 * @return {boolean}
 */
function shouldSlideChange() {
  const $nav = $(".pfs-header .navbar-nav");
  if ($nav[0] && $nav.is(":visible")) {
    const navRect = $nav[0].getBoundingClientRect();
    if (
      mousePosition.x >= navRect.left &&
      mousePosition.x <= navRect.left + navRect.width &&
      mousePosition.y >= navRect.top &&
      mousePosition.y <= navRect.top + navRect.height
    ) {
      return false;
    }
  }
  return true;
}

/**
 * Displays the appropriate left panel content section by adding the
 * `active` class to its container. Includes logic to not change the
 * active class if the footer is contained in either the origin or
 * destination slide.
 * @param {object} origin The origin slide.
 * @param {object} destination The destination slide.
 */
function showLeftPanelContent(origin, destination) {
  const $leftPanelSections = $leftPanel.find(".left-panel-content");
  const setActiveClass = () => {
    $leftPanelSections
      .removeClass("active")
      .eq(destination.index)
      .addClass("active");
  };

  try {
    const isFooterInvolved =
      $(origin.item)
        .add(destination.item)
        .has(".pfs-footer").length > 0;
    if (isFooterInvolved) {
      return;
    }
  } catch (err) {
    setActiveClass();
  }
  setActiveClass();
}

/**
 * Initializes the two-column full page scroll layout.
 */
function initTwoColumnLayout() {
  initFullpage(
    fullpageContainerSelector,
    createSectionColorArray(
      $(fullpageContainerSelector).find(".section").length,
      true
    ),
    {
      afterRender: () => showLeftPanelContent(null, { index: 0 }),
      onLeave: (origin, destination, direction) => {
        if (shouldSlideChange()) {
          if(keyboardControlInitialized) {
            if(direction === 'up') {
              manageFocusOnSlideUp(origin.item.isEqualNode(Array.from(document.querySelectorAll('.fp-section')).reverse()[0]));
            } else {
              manageFocusOnSlideDown();
            }
          }

          if (scrollType === SCROLL_TYPE_SCROLLING_LEFT_PANEL) {
            showLeftPanelContent(origin, destination);
          }
          return true;
        }
        return false;
      }
    }
  );
}

/**
 * Initializes single column full page scroll layout.
 * - Creates slides for the main content area from left panel content sections.
 * - Injects new slides into the full page container at the appropriate position.
 */
function initOneColumnLayout(config) {
  const $mainContentSections = $(fullpageContainerSelector).find(".section");
  const $leftNavSections = $leftPanel
    .clone()
    .find(".left-panel-content")
    .remove();

  const $sectionsToInject = $leftNavSections.toArray().map(section =>
    $("<section></section>")
      .addClass("section section-dynamic section-light")
      .append($("<div class='left-panel'></div>").append(section))
  );

  if (scrollType === SCROLL_TYPE_SCROLLING_LEFT_PANEL) {
    // In the case of a scrolling left panel, there is a 1:1 mapping of left
    // panel sections and main content sections (used on home page), except
    // when a main content section contains the footer - in that case, a left
    // panel content slide will not be inserted before it.
    $mainContentSections.toArray().forEach((section, i) => {
      if ($(section).has(".pfs-footer").length === 0) {
        $sectionsToInject[i].insertBefore($(section));
      }
    });
  } else {
    // In the case of a sticky left panel, there is only 1 left panel section
    // and a variable number of main content sections.
    $sectionsToInject[0].insertBefore($mainContentSections.toArray()[0]);
  }

  initFullpage(
    fullpageContainerSelector,
    createSectionColorArray($mainContentSections.length),
    Object.assign({
      onLeave: () => shouldSlideChange(),
      paddingTop: "80px",
    }, config)
  );
}

/**
 * Initializes a one column layout, with full page scroll functionality
 * disabled. Intended for use on mobile (viewports <=576px wide).
 */
function initManualScrollLayout() {
  $(fullpageContainerSelector)
    .find(".section")
    .children(":first-child")
    .not(":last")
    .addClass("fps-no-scroll");

  initOneColumnLayout({
    normalScrollElements: ".fps-no-scroll",
    paddingBottom: "80px",
    autoScrolling: false,
    fitToSection: false
  });
}


/**
 * mousemove event handler which saves a reference to the latest mouse position.
 */
function trackMousePosition() {
  $(document).mousemove(e => {
    mousePosition = { x: e.pageX, y: e.pageY };
  });
}

/**
 * Destroys and initializes full page scroll.
 * @param {Function} initLayoutMethod The layout initialization method to be invoked.
 */
function resetLayout(initLayoutMethod) {
  destroyFullpage();
  initLayoutMethod();
}

function init() {
  $fullpageContainer = $(fullpageContainerSelector);

  if ($fullpageContainer.length === 0) {
    return;
  }

  $leftPanel = $("#left-panel");
  scrollType = determineScrollType(
    $leftPanel.find(".left-panel-content").length,
    $fullpageContainer.find(".section:not(:has(.pfs-footer))").length
  );

  $fullpageContainer
    .toggleClass(
      "sticky-left-panel",
      scrollType === SCROLL_TYPE_STICKY_LEFT_PANEL
    )
    .toggleClass(
      "scrolling-left-panel",
      scrollType === SCROLL_TYPE_SCROLLING_LEFT_PANEL
    )
    .click(e => {
      if ($(e.target).closest(".full-page-arrow").length > 0) {
        moveFullPageSectionDown();
      }
    });

  // Manage tab control.
  if(window.innerWidth >= 992) {
    document.addEventListener('keydown', function(e) {
      if(e.key !== 'Tab') return;
      const activeEl = ally.get.activeElement();

      if(activeEl.closest('.swiper-container')) {
        // hide carousen arrows
        try {
          activeEl.closest('.swiper-container').querySelector('.swiper-button-prev').style.visibility = 'hidden';
          activeEl.closest('.swiper-container').querySelector('.swiper-button-next').style.visibility = 'hidden';
        } catch(err) {}
      } else {
        try {
          document.querySelectorAll('.swiper-button-prev').forEach(el => el.style.visibility = 'visible');
          document.querySelectorAll('.swiper-button-next').forEach(el => el.style.visibility = 'visible');
        } catch (err) {}
      }

      if(activeEl.closest('.left-panel-content')) {
        handleTabPressOnLeftPanel(e.shiftKey);
        return;
      }
      if(activeEl.closest('.fp-section')) {
        handleTabPressOnSlides(e);
        return;
      }
      if(activeEl.closest('.account-btn-container') || activeEl.closest('.social-btn-container')) {
        handleTabPressOnLeftRailFooter(e.shiftKey);
        return;
      }
      if(activeEl === document.querySelector('.nav-link.search-trigger') 
        || activeEl === document.querySelector('.navbar-toggler[aria-expanded=false]')
        || activeEl === ally.query.tabbable({context: '.navbar-nav'}).pop()) {
        handleTabPressOnLastNavEl();
        return;
      }
    });
    keyboardControlInitialized = true;
  }

  trackMousePosition();

  enquire
    .register(MQ_MOBILE_SMALL, {
      match() {
        resetLayout(initManualScrollLayout);
      }
    })
    .register(MQ_MOBILE_WIDE_ONLY, {
      match() {
        resetLayout(initOneColumnLayout);
      }
    })
    .register(MQ_TABLET, {
      match() {
        resetLayout(initTwoColumnLayout);
      }
    });
}

const fullPageScroll = { init };

export default fullPageScroll;
