const players = [];
let videoTutorials;

/** TODO: abstract the video out of the video tutorial component if it is needed in more than one place */

/**
 * Assign handlers to each poster to begin playing the Youtube video on click
 */
function onPlayerReady() {
  for (let i = 0; i < videoTutorials.length; i += 1) {
    const videoPoster = videoTutorials[i].querySelector(".video-tutorial-poster")
    videoPoster.addEventListener("click", e => {
      e.preventDefault();
      videoPoster.remove();
      players[i].playVideo();
    });
    videoPoster.addEventListener("keydown", e => {
      if (e.keyCode === 32) {
        e.preventDefault();
        videoPoster.remove();
        players[i].playVideo();
      }
    })
  }
}

/**
 * Uses Youtube's iframe API to create a player object
 * @param {HTMLElement} videoPlayer
 * @returns {Object}
 */
function createYoutubePlayer(videoPlayer) {
  return new YT.Player(videoPlayer.querySelector("iframe").id, {
    events: {
      onReady: onPlayerReady
    }
  });
}

/**
 * Sets up Youtube's iframe API which allows us to play embedded Youtube videos programmatically with JS
 * @param {HTMLElement} videoTutorials
 */
function applyYoutubeAPI() {
  const isYoutubeScriptTagCreated = document.querySelectorAll("#youtube-script-tag").length > 0;

  if (!isYoutubeScriptTagCreated) {
    const tag = document.createElement("script");
    const firstScriptTag = document.getElementsByTagName("script")[0];
    tag.id = "youtube-script-tag";
    tag.src = "https://www.youtube.com/iframe_api";
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
  }

  /**
   *  1. Assign an ID to each Youtube player iframe on the page
   *  2. Create the player and add it to the `players` array, which allows there to be multiple videos on a single page
   */
  window.onYouTubeIframeAPIReady = () => {
    for (let i = 0; i < videoTutorials.length; i += 1) {
      videoTutorials[i].getElementsByTagName("iframe")[0].id = `video-tutorial-iframe-${i}`;
      players.push(createYoutubePlayer(videoTutorials[i]));
    }
  };
}

const videoTutorial = {
  init() {
    videoTutorials = document.querySelectorAll(".video-tutorial");
    applyYoutubeAPI();
  }
};

export default videoTutorial;
