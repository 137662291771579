import $ from "jquery";
import enquire from "enquire.js";
import debounce from "lodash/debounce";
import anchorTags from "../utils/anchor-tags";
import { MQ_MOBILE_SMALL } from "../constants";

const REDUCED_HEIGHT_HORIZONTAL_CLASS = "product-grid-reduced-height";
const REDUCED_HEIGHT_VERTICAL_CLASS = "product-grid-reduced-height-left-rail-visible";

let topRight;
let bottomRight;

/** NOTE:
 * If this method is needed for another component, consider moving it to util/check-element-height.js.
 * Requires anchorTags.getNavHeight.
 * Assume jQuery is not available.
 *
 * Compare the el height to the height of the window, minus the fixed nav height
 * @param {Object} el the element to compare
 * @returns {boolean}
 */
function isElementTooTall(el) {
  return window.innerHeight - parseInt(anchorTags.getNavHeight(), 10) < el.clientHeight;
}

/**
 * Check if the first .left-rail in the DOM is visible; we are not concerned with potential duplicate .left-rail elements in .main-content
 * @returns {boolean}
 */
function isLeftRailVisible() {
  return getComputedStyle(document.querySelector(".left-rail")).display !== "none";
}

/**
* Use the img.src to set the background-image on the parent component to ensure the image fills the space completely
* @param {Object} el the component's container=
*/
function convertImgToBackgroundImage(el) {
  /**
   * 1. get all the img elements in the container,
   * 2. create an array of the img.src of all the img elements in the container
   * 3. create an array of the img parents on which the background-image will be set
  */
  const images = [...el.querySelectorAll("img")];
  const imageURLs = images.map(image => image.src);
  const imageParentElements = images.map(image => image.parentNode);

  imageParentElements.forEach((imageParentElement, i) => imageParentElement.style.backgroundImage = `url(${imageURLs[i]})`);
}

/**
* Clean up the background-images when they are no longer needed
* @param {Object} el the component's container
*/
function removeBackgroundImages(el) {
  /** create an array of the img parents on which the background-image will be set */
  const imageParentElements = [...el.querySelectorAll("img")].map(image => image.parentNode);

  imageParentElements.forEach((imageParentElement, i) => imageParentElement.style.backgroundImage = "");
}

/**
 * 1. Check if .product-grid-container is taller than the window height.
 * 2. If so, apply the appropriate classes to adjust the product grid styles.
 * 3. Change <img> elements to background images
 * Since the isElementTooTall method requires the el parameter, we create this method to bind to the window.resize listener.
 */
function checkProductGridHeight() {
  const productGridContainer = document.querySelector(".product-grid-container");

  if (productGridContainer) {
    /** Remove reduced height classes so the element's natural height can be compared against the window height for an accurate result */
    productGridContainer.classList.remove(REDUCED_HEIGHT_HORIZONTAL_CLASS, REDUCED_HEIGHT_VERTICAL_CLASS);
    removeBackgroundImages(productGridContainer);

    if (isElementTooTall(productGridContainer) && ![...productGridContainer.classList].includes("no-scroll") && !isLeftRailVisible()) {
      productGridContainer.classList.add(REDUCED_HEIGHT_HORIZONTAL_CLASS);
      convertImgToBackgroundImage(productGridContainer);
    } else if (isElementTooTall(productGridContainer) && ![...productGridContainer.classList].includes("no-scroll") && isLeftRailVisible()) {
      productGridContainer.classList.add(REDUCED_HEIGHT_VERTICAL_CLASS);
      convertImgToBackgroundImage(productGridContainer);
    }
  }
}

function checkWidth() {
  topRight.css("opacity", 0.5);
  bottomRight.css("opacity", 0.5);

  $(".product-grid-container").scroll(() => {
    const s = $(".product-grid-container").scrollLeft();
    const opacityVal = s / 150 + 0.5;

    topRight.css("opacity", opacityVal);
    bottomRight.css("opacity", opacityVal);
  });
}

const productGrid = {
  init() {
    topRight = $(".square-top-right");
    bottomRight = $(".square-bottom-right");

    enquire.register(MQ_MOBILE_SMALL, {
      match() {
        checkWidth();
      },
      unmatch() {
        topRight.css("opacity", 1);
        bottomRight.css("opacity", 1);
      }
    });

    window.addEventListener("resize", debounce(checkProductGridHeight, 200));
    checkProductGridHeight();
  }
};

export default productGrid;
