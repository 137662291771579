
import 'whatwg-fetch';
import loaderAnimation from "../utils/loader-animation";
import config from "../app-config";
import { SEARCH_REQUEST_COMPLETE } from "../events";
import searchResultsSuccess from "../mocks/search-results-success";
import dynamicListingSuccess from "../mocks/dynamic-listing-success";

/**
 * TO DO: figure out a more elegant way to handle mock data for the style guide. Perhaps with a mock ajax request service.
*/
let searchData = searchResultsSuccess;
let dynamicListingData = config.search.dynamicListingData || {};

const resultsToDisplayPerRequest = config.search.searchResultsPerPage || 7;
const searchBoxLabelWithSearchTerm = config.search.searchBoxLabelWithSearchTerm || "You searched for";
const searchBoxLabelWithoutSearchTerm = config.search.searchBoxLabelWithoutSearchTerm || "Can we help you find something?";
const noResultsMessage = config.search.noResultsMessage || "There are no results for the selected search criteria.";
const labelResults = config.search.labelResults || "Results";
const labelFor = config.search.labelFor || "for";

let isInitialRequest = true;
let isDynamicListingsPage = false;
let searchAPI;
let searchFilterContainer;
let searchFilterTriggers;
let searchFilterForm;
let searchBoxForm;
let searchBox;
let searchBoxLabel;
let searchResultsEl;
let searchResultsTotalResults;
let viewMoreResultsButton;
let currentResultsPage = 1;

/**
 * Displays a loader animation while sending an AJAX request for search results.
 * Update the query string, if necessary.
 * Update the searchData varaible with the response.
 * Rerender the filters and results when a response is received.
 * Hide the loader animation.
 * @param {string =} criteria the value of the ticked checkbox
 * @param {number = 1} pageNumber which page number of results to request
 */
function requestSearchResults({
    criteria,
    pageNumber= 1
  } = {}) {
  let queryParameters = getQueryStringParameters();
  let searchCriteria;
  let destroyOldResults = isInitialRequest || criteria ? true : false;

  loaderAnimation.show();

  if(criteria) {
    toggleFilterIsActive(criteria);
    setQueryStringParameters();
    currentResultsPage = 1;
  }

  searchCriteria = !isInitialRequest ? getSearchCriteria({pageNumber}) : getSearchCriteria({pageNumber: 1, searchTerm: queryParameters.searchTerm, metaData: queryParameters.metaData});
  isInitialRequest = false;

  fetch(searchAPI, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(searchCriteria)
  })
  .then(function(response) {
    if (response.status >= 200 && response.status < 300) {
      return response.json();
    } else {
      const error = new Error(response.statusText);
      error.response = response;
      throw error;
    }
  })
  .then(function(data) {
    searchData = data;
    updateSearchResults({results: searchData.SearchResults, destroyOldResults});
    createSearchFilters(searchData.Filters);
    updateSearchResultsCountText(searchData.SearchResults);
    updateSearchBoxLabel();
    updateLoadMoreButtonVisibility(searchData.TotalPages);
    // The form value may need to be updated when search is performed via the header modal.
    setDisplayedSearchTerm(queryParameters.searchTerm);
    loaderAnimation.hide();
    document.dispatchEvent(new Event(SEARCH_REQUEST_COMPLETE));
  })
  .catch(function(error) {
    // The form value may need to be updated when search is performed via the header modal.
    setDisplayedSearchTerm(queryParameters.searchTerm);
    loaderAnimation.hide();
    document.dispatchEvent(new Event(SEARCH_REQUEST_COMPLETE));
    console.log(`Error in POST request ${ searchAPI }:`);
    console.log(error);
    console.log('POST body:');
    console.log(searchCriteria);
  });
}

/**
 * Find the filter by value
 * @param {string} checkbox the GUID to match and toggle the IsChecked value.
 */
function toggleFilterIsActive(checkbox) {
  searchData.Filters.forEach(filter => {
    if (filter.TagId === checkbox.value) {
      filter.IsActive = checkbox.checked;
    }
  });
}

/**
 * Returns the search criteria to pass the API
 * @param {number=1} pageNumber the page from which to start the query.
 * @param {string=} searchTerm the terms to search on
 * @param {string=} metaData the TagIds to search on
 * @returns {object}
 */
function getSearchCriteria({
    pageNumber = 1,
    searchTerm = getDisplayedSearchTerm(),
    metaData = getQueryStringParameters().metadata
  } = {}) {
  return {
    SearchTerm: searchTerm,
    Templates: [],
    MetaData: metaData,
    PageNumber: pageNumber,
    PageSize: resultsToDisplayPerRequest
  }
}

/**
 * Returns checked filters
 * @returns {array}
 */
function getActiveFilters() {
  const checkedItems = [...searchFilterForm.querySelectorAll(":checked")];
  return checkedItems.map(checkedItem => checkedItem.value);
}

/**
 * Loop through the query string and create a shallow object of the parameters (the metadata string is then converted into an array)
 * @returns {object} an object with a key/value pair for each query string parameter
 */
function getQueryStringParameters() {
  const parameters = window.location.search
   .substring(1)
   .split("&")
   .reduce(function (acc, cur) {
     const paramString = cur.split('=');
     acc[paramString[0]] = paramString[1];
     return acc;
  }, {});

  // convert metadata array string into an actual array
  if(parameters.metadata) {
    parameters.metadata = parameters.metadata
      .replace("[", "")
      .replace("]", "")
      .replace("%5B", "")
      .replace("%5D", "")
      .replace("%2C", ",")
      .split(",");
  } else {
    parameters.metadata = [];
  }

  // prevent searchTerm key from being undefined
  parameters.searchTerm = parameters.searchTerm || "";

  return parameters;
}

/**
 * Pushes an updated query string to the browser history
 */
function setQueryStringParameters () {
  let newQueryString = `?metadata=[${getActiveFilters()}]&searchTerm=${getDisplayedSearchTerm()}&page=1`;

  history.pushState(null, null, `${location.protocol}//${location.host}${location.pathname}${newQueryString}`);
}

/**
 * Pushes an updated searchTerm query string with no metadata selected to the browser history
 */
function setNewSearchtermQueryStringParameters (searchTerm) {
  let newQueryString = `?searchTerm=${searchTerm}&metadata=[]&page=1`;

  history.pushState(null, null, `${location.protocol}//${location.host}${location.pathname}${newQueryString}`);
  isInitialRequest = true;
  requestSearchResults();
}

/**
 * Returns an array of the filter tag names
 * @param {object} filters
 * @returns {array}
 */
function getFilterCategories(filters) {
  return [...new Set(filters.map(filter => filter.Tag))];
}

/**
 * Set the value in the searchBox field
 * @param {searchTerm = ""} an optional string to display in the searchBox field
 */
function setDisplayedSearchTerm(searchTerm = "") {
  searchBox.value = decodeURIComponent(searchTerm);
}

/**
 * Get the value in the searchBox field
 * @returns {string}
 */
function getDisplayedSearchTerm() {
  return searchBox.value;
}

/**
 * Sorts the filters parameter into an array of objects grouped by filter.Tag value.
 * Generate accordion markup for each group of filters.
 * Replaces the old search filter HTML with updated search filters HTML
 * @param {object} filters an array made up of filter objects
 */
function createSearchFilters(filters) {
  const filterCategories = getFilterCategories(filters);
  const sortedFilters = [];

  let markup = "";

  filterCategories.map(filterCategory => {
    sortedFilters.push({
      Title: filterCategory,
      Filters: filters.filter(filter => filter.Tag === filterCategory)
    });
  });

  sortedFilters.map(sortedFilterGroup => markup += generateAccordionMarkup(sortedFilterGroup)).join("");
  searchFilterForm.innerHTML = markup;
}

/**
 * Iterates over the objects in the results array and adds additional results HTML to the page
 * @param {array} results an array made up of search result objects
 * @param {boolean=} destroyOldResults remove old results when filters change
 */
function updateSearchResults({
    results,
    destroyOldResults = false
  } = {}) {
  let markup = destroyOldResults ? "" : searchResultsEl.innerHTML;

  /** Clear out existing search results if no results have been returned */
  if (!results || results.length === 0) {
    searchResultsEl.innerHTML = "";
    return;
  }

  results.map((result) => {
      markup += generateSearchResultMarkup(result);
    }).join("");

  searchResultsEl.innerHTML = markup;
}

/**
 * Creates an object and calculates missing keys to reshape the data to match the data returned from a search request,
 * so methods built for search can work with it.
 * @param {array} dynamicListingData the initial array of results
 * @returns {object} reshaped data
 */
function massageDynamicListingsData (dynamicListingData) {
  const refactoredDynamicListingData = {};

  refactoredDynamicListingData.SearchResults = dynamicListingData || [];
  refactoredDynamicListingData.TotalResults = refactoredDynamicListingData.SearchResults.length;
  refactoredDynamicListingData.TotalPages = Math.ceil(refactoredDynamicListingData.TotalResults / resultsToDisplayPerRequest);

  return refactoredDynamicListingData;
}

/**
 * Creates a subset of the next results to load
 * @returns {array} subset of results
 */
function createDynamicListingDataSlice() {
  const offsetStart = resultsToDisplayPerRequest * (currentResultsPage - 1);
  const offsetEnd = resultsToDisplayPerRequest + offsetStart;
  const dynamicListingDataSlice = dynamicListingData.SearchResults.slice(offsetStart, offsetEnd);

  return dynamicListingDataSlice;
}

/**
 * Updates searchResultsTotalResults element text: "X Results: for Y"
 */
function updateSearchResultsCountText(results) {
  let text;
  if (results.length === 0) {
    text = noResultsMessage;
  } else if (searchData.SearchTerm.trim().length === 0) {
    text = `${searchData.TotalResults} ${labelResults}`;
  } else {
    text = `${searchData.TotalResults} ${labelResults} ${labelFor} ${searchData.SearchTerm}`;
  }
  searchResultsTotalResults.innerHTML = text;
}

/**
 * Updates the search box label
 */
function updateSearchBoxLabel() {
  searchBoxLabel.innerHTML = getDisplayedSearchTerm().length > 0 ? searchBoxLabelWithSearchTerm : searchBoxLabelWithoutSearchTerm;
}

/**
 * Increments the currentResultsPage variable.
 * Requests new search results starting with the currentResultsPage.
 */
function loadMoreResults() {
  currentResultsPage += 1;
  if (isDynamicListingsPage) {
    updateSearchResults({ results: createDynamicListingDataSlice() });
    updateLoadMoreButtonVisibility(dynamicListingData.TotalPages);
  } else {
    requestSearchResults({ pageNumber: currentResultsPage });
  }
}

/**
 * Determines whether there are additional pages beyond currentResultsPage; if so, viewMoreResultsButton is shown.
 * @param {number} totalPages total pages in the response according to the TotalPages key
 */
function updateLoadMoreButtonVisibility(totalPages) {
  viewMoreResultsButton.style.display = currentResultsPage >= totalPages ? "none" : "block";
}

/**
 * Determine if result anchor tags should have a target attribute and if so, return the appropriate markup
 * @param {object} result an object containing a single search result's data
 * @returns {string} if the result's LinkTarget key has a value, target markup is returned
 */
function setTargetAttribute(result) {
  return result.LinkTarget.length > 0 ? ` target="${result.LinkTarget}"` : "";
}

/**
 * Determines if and how an image should display.
 * @param {object} result an object containing a single search result's data
 * @returns {string}
 *    If there is a URL and image defined for the result, an <a> element is returned.
 *    If there is an image defined but no URL, a <div> with a background image is returned.
 *    If no image is defined, an empty string is returned.
 */
function generateSearchResultImage(result) {
  let markup = "";

  if (result.LinkUrl && result.Image) {
    markup = `<a aria-label="${result.LinkText}" class="listing-item-image" href="${result.LinkUrl}" alt="" style="background-image: url(${result.Image})" ${setTargetAttribute(result)}></a>`;
  } else if (!result.LinkUrl && result.Image) {
    markup = `<div aria-label="${result.LinkText}" class="listing-item-image" alt="" style="background-image: url(${result.Image})"></div>`;
  }

  return markup;
}

/**
 * Determines how a search result title should display
 * @param {object} result an object containing a single search result's data
 * @returns {string}
 *    If there is a URL, an <h3> nested in an <a> element is returned.
 *    If no URL is defined, an <h3> is returned.
 */
function generateSearchResultTitle(result) {
  return result.LinkUrl ? `<h4><a class="listing-item-title-link" href="${result.LinkUrl}" ${setTargetAttribute(result)}>${result.Title}</a></h4>` : `<h4>${result.Title}</h4>`;
}

/**
 * Determines how a search result title should display
 * @param {object} result an object containing a single search result's data
 * @returns {string}
 *    If there is a URL, an <h3> nested in an <a> element is returned.
 *    If no URL is defined, an <h3> is returned.
 */
function generateCTALink(result) {
  return result.LinkText && result.LinkUrl  ? `<p><a class="cta-link" href="${result.LinkUrl}" ${setTargetAttribute(result)}>${result.LinkText}</a></p>` : "";
}

/**
 * Determines how a search result summary should display
 * @param {object} result an object containing a single search result's data
 * @returns {string}
 */
function generateResultSummary(result) {
  return result.Summary ? `<p>${result.Summary}</p>` : "";
}

/**
 * Determines how a search result summary should display
 * @param {object} result an object containing a single search result's data
 * @returns {string}
 */
function generateResultTag(result) {
  return result.Tag ? `<h3>${result.Tag}</h3>` : "";
}

/**
 * Populates and returns the HTML for a search result
 * @param {object} result an object containing a single search result's data
 * @returns {string} an <li> element
 */
function generateSearchResultMarkup(result) {
  return (
    `<li class="listing-item">
      ${generateSearchResultImage(result)}
      <div class="listing-item-text">
        ${generateResultTag(result)}
        ${generateSearchResultTitle(result)}
        ${generateResultSummary(result)}
        ${generateCTALink(result)}
      </div>
    </li>`
  )
}

/**
 * Checks if the query string metadata contains the filter's TagId
 * @param {object} filter an object containing a single filter's data
 * @returns {boolean}
 */
function shouldBeChecked(filter) {
  return getQueryStringParameters().metadata && getQueryStringParameters().metadata.includes(filter.TagId);
}

/**
 * Populates and returns the HTML for a filter.
 * If there are no search results for this filter, it is disabled.
 * If shouldBeChecked returns true, the checkbox is checked.
 * @param {object} filter an object containing a single filter's data
 * @returns {string} an <li> element
 */
function generateFilterMarkup(filter) {
  return (
    `<li class="search-filter-list-item">
      <label class="pfs-label form-check-label pfs-form-check-label pfs-form-check-label-checkbox search-filter-label
        ${filter.Count === 0 ? " search-filter-label-disabled" : ""}">
        <input type="checkbox"
          class="form-check-input pfs-form-check-input pfs-form-check-input-checkbox search-filter-checkbox"
          value="${filter.TagId}"
          ${filter.Count === 0 ? "disabled" : ""}
          ${shouldBeChecked(filter) && filter.Count !== 0 ? "checked": ""}>
          ${filter.Title}
      </label>
    </li>`
  )
}

/**
 * Populates and returns the HTML for a filter group accordion.
 * A unique ID is created based on the filterGroup.Title, as there should not be duplicates from the API response.
 * @param {object} filterGroup an object containing a single filter group's data
 * @returns {string} a <fieldset> element
 */
function generateAccordionMarkup(filterGroup) {
  const uniqueAccordionID = filterGroup.Title.toLowerCase().split(" ").join("-");
  const containsCheckedFilter = filterGroup.Filters.some(filter => shouldBeChecked(filter));

  return (
    `<fieldset class="accordion search-filters-accordion">
      <legend class="search-filters-legend" id="search-group-${uniqueAccordionID}-title">
        <button class="search-filters-header"
          type="button"
          data-toggle="collapse"
          data-target="#search-group-${uniqueAccordionID}"
          aria-controls="search-group-${uniqueAccordionID}"
          ${containsCheckedFilter ? "aria-expanded='true'" : "aria-expanded='false'"}>
            ${filterGroup.Title}
        </button>
      </legend>
      <ul class="collapse ${containsCheckedFilter ? "show" : ""} search-filter-list" id="search-group-${uniqueAccordionID}" aria-labelledby="search-group-${uniqueAccordionID}-title">
        ${filterGroup.Filters.map(filter => generateFilterMarkup(filter)).join("")}
      </ul>
    </fieldset>`
  )
}

/**
 * Adds necessary event listeners for search page
 */
function registerSearchEventListeners() {
  /** -searchFilterTriggers - listens for clicks on the show/hide filter button on mobile) */
  searchFilterTriggers.forEach(searchFilterTrigger => {
    searchFilterTrigger.addEventListener("click", () => {
      searchFilterContainer.classList.toggle("search-filters-active");
    });
  });

  /** -searchFilterForm - detects when a user checks/unchecks a checkbox */
  searchFilterForm.addEventListener("change", e => {
    requestSearchResults({criteria: e.target})
  });

  searchBoxForm.addEventListener("submit", e => {
    const newSearchTerm = getDisplayedSearchTerm();

    e.preventDefault();

    /** Compare previous and current search values to prevent unnecessary searches */
    if(newSearchTerm.trim().length > 0 && encodeURI(newSearchTerm).toLowerCase() !== getQueryStringParameters().searchTerm.toLowerCase()) {
      setNewSearchtermQueryStringParameters(newSearchTerm);
      isInitialRequest = true;
      // Search metadata is included in this request to maintain filter state when users come to a pre-filtered search page
      // e.g. via pill links beneath articles, or the Recipes link on the Get Inspired landing page.
      // This has the side effect of maintaining the filter(s) if the user searches for a new term using the search field on the page.
      requestSearchResults({criteria: getQueryStringParameters()});
    }
  })
  registerLoadMoreListener();
}

/**
 * Adds necessary event listener for the View More button.
 * This method is separate because it is used on both the search results and dynamic listing pages
 */
function registerLoadMoreListener() {
  viewMoreResultsButton.addEventListener("click", (e) => loadMoreResults());
}

const search = {
  init() {
    searchFilterContainer = document.querySelector("#search-filters");
    searchResultsEl = document.querySelector(".listing");

    /**
     * If searchFilterContainer is present, then invoke the search-specific JS.
     * If searchFilterContainer is not present, but searchResultsEl is present, then invoke the dynamic listings page JS.
     */
    if (searchFilterContainer) {
      searchFilterTriggers = [...document.querySelectorAll(".search-filters-trigger")];
      searchFilterForm = searchFilterContainer.querySelector("form");
      searchAPI = config.isDev ? "/searchSuccess" : searchFilterForm.action;
      searchBoxForm = document.querySelector(".search-results-search-box");
      searchBoxLabel = searchBoxForm.querySelector(".search-label");
      searchBox = searchBoxForm.querySelector(".search-input");
      searchResultsEl = document.querySelector(".listing");
      searchResultsTotalResults = document.querySelector(".search-results-total-results");
      viewMoreResultsButton = document.querySelector(".search-results-view-more-btn");

      requestSearchResults();
      registerSearchEventListeners();

    } else if (searchResultsEl) {
      isDynamicListingsPage = true;
      viewMoreResultsButton = document.querySelector(".search-results-view-more-btn");

      // Use mock data for style guides on local, dev, and staging
      if (config.isDev) {
        dynamicListingData = massageDynamicListingsData(dynamicListingSuccess);
      } else {
        // Use dynamic listing data on the config.search object
        dynamicListingData = massageDynamicListingsData(config.search.dynamicListingData);
      }

      if (viewMoreResultsButton) {
        updateSearchResults({results: createDynamicListingDataSlice()});
        updateLoadMoreButtonVisibility(dynamicListingData.TotalPages);
        registerLoadMoreListener();
      }
    }
  }
};

export default search;
