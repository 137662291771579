import { PDFDocument, rgb, StandardFonts } from "pdf-lib";


// Note to dev, the path to the font-awesome-red-checkmark.png is different in local and sitecore
// path in local
// const fontAwesomeRedCheckMark = "../../../assets/fonts/font-awesome-red-checkmark.png";
//path in sitecore
const fontAwesomeRedCheckMark =  "/Sites/PFS/UI/dist/assets/fonts/font-awesome-red-checkmark.png";

function getRecipeContent(recipe) {
  const recipeContent = recipe;
  const recipeHeading = recipeContent.querySelector("h2").innerText;

  const ingredientsSection = recipeContent.querySelector(".recipe-ingredients");
  const ingredientsHeading = ingredientsSection.querySelector("h3").innerText;
  const ingredientsList = ingredientsSection.querySelector(".recipe-ingredient-list");
  const listItems = Array.from(ingredientsList.querySelectorAll("li")).map(li => li.innerText);

  const directionsSection = recipeContent.querySelector(".recipe-directions");
  const directionsHeading = directionsSection.querySelector("h3").innerText;
  const directionsList = directionsSection.querySelector("ol");
  const directionsItems = Array.from(directionsList.querySelectorAll("li")).map(li => li.innerText.replaceAll('℉', 'F'));

  return {
    recipeHeading,
    ingredientsHeading,
    listItems,
    directionsHeading,
    directionsItems
  };
}

async function getImage(pdfDoc, width) {
  const imgElement = document.querySelector(".hero-carousel-item img");
  const imgUrl = imgElement.src;
  const imgResponse = await fetch(imgUrl);
  if (!imgResponse.ok) throw new Error(`Error fetching image: ${imgResponse.statusText}`);

  const imgContentType = imgResponse.headers.get("Content-Type");
  let img;

  if (imgContentType === "image/jpeg") {
    img = await pdfDoc.embedJpg(await imgResponse.arrayBuffer());
  } else if (imgContentType === "image/png") {
    img = await pdfDoc.embedPng(await imgResponse.arrayBuffer());
  } else {
    throw new Error("Unsupported image format");
  }

    // Get original image dimensions
    const { width: originalImgWidth, height: originalImgHeight } = img.scale(1);
  
    // Calculate the maximum allowed width for the image with padding
    const maxImgWidth = width - 100; // 50 points padding on each side
    let imgWidth = originalImgWidth;
    let imgHeight = originalImgHeight;

    // Scale the image proportionally if it exceeds the max width
    if (originalImgWidth > maxImgWidth) {
      const scaleFactor = maxImgWidth / originalImgWidth;
      imgWidth = originalImgWidth * scaleFactor;
      imgHeight = originalImgHeight * scaleFactor;
    }

  return {
    img,
    imgWidth,
    imgHeight
  }
}

function findAllRecipes () {
  const recipes = document.querySelectorAll('.rte-content.recipe');
  return recipes;
}

async function generatePdfForRecipe() {
  const allRecipeContent = findAllRecipes();
  const mainRecipeHeading = document.querySelector('.hero-carousel-heading-container .heading-title').innerText;

  // Letter size dimensions
  const width = 8.5 * 72; // 8.5 inches to points
  const height = 11 * 72;  // 11 inches to points
  const padding = 100; // Padding from the right edge
  const copyrightText = "© Copyright 2024 Performance Foodservice";
  const fontSize = 12;

  const pdfDoc = await PDFDocument.create();
  let page = pdfDoc.addPage([width, height]);

  // Fetch and add check mark image
  const checkMarkWidth = 12;
  const checkMarkHeight = 12;
  const checkMarkResponse = await fetch(fontAwesomeRedCheckMark);
  if (!checkMarkResponse.ok) throw new Error(`Error fetching check mark image: ${checkMarkResponse.statusText}`);
  const checkMarkImg = await pdfDoc.embedPng(await checkMarkResponse.arrayBuffer());

  // Fetch byline and author
  const byline = document.querySelector('.recipe-content').querySelector(".byline")?.innerText || '';
  const authorAnchor = document.querySelector('.recipe-content').querySelector(".author");
  const authorText = authorAnchor ? authorAnchor.innerText : '';

  if (!allRecipeContent) return;

  try {
    const font = await pdfDoc.embedFont(StandardFonts.Helvetica);
    const boldFont = await pdfDoc.embedFont(StandardFonts.HelveticaBold);
    const {
      img,
      imgWidth,
      imgHeight
    } = await getImage(pdfDoc, width);

    // Recipe Heading
    const headingFontSize = 31;
    const headingMaxWidth = width - padding;
    let headingY = height - 80;
    let headingLine = "";
    const headingWords = mainRecipeHeading.split(" ");

    // Draw main heading with wrapping for recipe
    headingWords.forEach(word => {
      const lineWithWord = headingLine + word + " ";
      const lineWidth = boldFont.widthOfTextAtSize(lineWithWord, headingFontSize);
      if (lineWidth < headingMaxWidth) {
      headingLine = lineWithWord;
      } else {
      page.drawText(headingLine.trim(), 
        { 
        x: 50, 
        y: headingY, 
        size: headingFontSize, 
        font: boldFont, 
        color: rgb(0, 0, 0) 
        }
      );
      headingY -= headingFontSize + 10; // Move down for the next line
      headingLine = word + " ";
      }
    });

    // Draw any remaining text in the last line
    if (headingLine) {
      page.drawText(headingLine.trim(),
      { 
        x: 50, 
        y: headingY, 
        size: headingFontSize, 
        font: boldFont, 
        color: rgb(0, 0, 0) 
      }
      );
    }

    // Draw the image
    page.drawImage(img, 
      { 
        x: (width - imgWidth) / 2, // Center the image horizontally
        y: height - imgHeight - 150, 
        width: imgWidth, 
        height: imgHeight 
      }
    );

    // Track the Y axis to draw the remaining content
    let currentY = height - imgHeight - 210;

    allRecipeContent.forEach(recipe => {
      const {
        recipeHeading,
        ingredientsHeading,
        listItems,
        directionsHeading,
        directionsItems
      } = getRecipeContent(recipe);

        // Recipe Heading
        const recipeHeadingFontSize = 28;
        const recipeHeadingMaxWidth = width - padding;
        let recipeHeadingY = currentY;
        let recipeHeadingLine = "";
        const recipeHeadingWords = recipeHeading.split(" ");

        recipeHeadingWords.forEach(word => {
          const lineWithWord = recipeHeadingLine + word + " ";
          const lineWidth = boldFont.widthOfTextAtSize(lineWithWord, recipeHeadingFontSize);
          if (lineWidth < recipeHeadingMaxWidth) {
            recipeHeadingLine = lineWithWord;
          } else {
            if (recipeHeadingY < 50) {
              page = pdfDoc.addPage([width, height]);
              recipeHeadingY = height - 50; // Adjusted Y position after adding a new page
            }
            page.drawText(recipeHeadingLine.trim(), 
              { 
          x: 50, 
          y: recipeHeadingY, 
          size: recipeHeadingFontSize, 
          font: boldFont, 
          color: rgb(0, 0, 0) 
              }
            );
            recipeHeadingY -= recipeHeadingFontSize + 10; // Move down for the next line
            recipeHeadingLine = word + " ";
          }
        });

        // Draw any remaining text in the last line
        if (recipeHeadingLine) {
          if (recipeHeadingY < 50) {
            page = pdfDoc.addPage([width, height]);
            recipeHeadingY = height - 50; // Adjusted Y position after adding a new page
          }
          page.drawText(recipeHeadingLine.trim(),
          { 
            x: 50, 
            y: recipeHeadingY, 
            size: recipeHeadingFontSize, 
            font: boldFont, 
            color: rgb(0, 0, 0) 
          }
        );
      }
      
      currentY = recipeHeadingY - 40;

      page.drawText(ingredientsHeading, 
      { 
        x: 50, 
        y: currentY, 
        size: 21, 
        font: boldFont, 
        color: rgb(0, 0, 0) 
      }
    );

    currentY -= 30;

    // Draw Ingredients
    listItems.forEach(item => {
      if (currentY - 50 < 0) {
        page = pdfDoc.addPage([width, height]);
        currentY = height - 50; // Adjusted Y position after adding a new page
      }
    
      page.drawImage(checkMarkImg, 
        { 
          x: 50, 
          y: currentY, 
          width: checkMarkWidth, 
          height: checkMarkHeight 
        }
      );
    
      page.drawText(item, 
        { 
          x: 70, 
          y: currentY, 
          size: fontSize, 
          font, 
          color: rgb(0, 0, 0) 
        }
      );
    
      currentY -= 40;
    });

    // Draw Directions
    const directionsHeadingY = currentY - 30;
    page.drawText(directionsHeading, 
      { 
        x: 50, 
        y: directionsHeadingY, 
        size: 21, 
        font: boldFont, 
        color: rgb(0, 0, 0) 
      }
    );

    currentY = directionsHeadingY - 30;
    directionsItems.forEach((direction, index) => {
      // Add step number
      const stepText = `Step ${index + 1}`;
      page.drawText(stepText,
        { 
          x: 50, 
          y: currentY, 
          size: 14, 
          font, 
          color: rgb(0, 0, 0) 
        }
      );
    
      currentY -= 25; // Space after "Step" text
    
      let line = "";
      const directionWords = direction.split(" ");
      directionWords.forEach(word => {
        const lineWithWord = line + word + " ";
        const lineWidth = font.widthOfTextAtSize(lineWithWord, fontSize);
        if (lineWidth < width - padding) { // Check for padding
          line = lineWithWord;
        } else {
          if (currentY < 50) {
            page = pdfDoc.addPage([width, height]);
            currentY = height - 50; // Adjusted Y position after adding a new page
          }
          page.drawText(line.trim(), 
            { 
              x: 50,
              y: currentY,
              size: fontSize,
              font, 
              color: rgb(0, 0, 0)
            }
          );
    
          currentY -= 20;
          line = word + " ";
        }
      });
    
      if (line) {
        if (currentY < 50) {
          page = pdfDoc.addPage([width, height]);
          currentY = height - 50; // Adjusted Y position after adding a new page
        }
        page.drawText(line.trim(),
          { 
            x: 50,
            y: currentY,
            size: fontSize,
            font,
            color: rgb(0, 0, 0)
          }
        );
    
        currentY -= 20;
      }
      currentY -= 30; // Extra space after each direction step
    });
      currentY -= 30;
    })
    
    // Draw Byline
    const bylineY = currentY - 5;
    let bylineWords = byline.split(" ");

    // Check if there are at least two words in the byline
    const authorNameWidth = boldFont.widthOfTextAtSize(authorText, 10);

    if (bylineWords.length > 2) {
      bylineWords = bylineWords.slice(2); // Skip the first two words
    }

    if (authorText) {
      // Draw the author text
      page.drawText(authorText, 
        { 
          x: 50, 
          y: bylineY, 
          size: 10, 
          font: boldFont, // Use bold font
          color: rgb(0, 0, 0)
        }
      );

      // Draw the underline (a line of the same width as the text)
      page.drawLine({
        start: { x: 50, y: bylineY - 2 }, // Slightly below the text
        end: { x: 50 + authorNameWidth, y: bylineY - 2 },
        thickness: 1,
        color: rgb(0, 0, 0),
      });
    }

    // Now draw the remaining byline text starting from the right position
    let bylineLine = "";
    const bylineMaxWidth = width - padding; // Maximum width for the byline
    let bylineCurrentY = bylineY; // Y position for byline
    let currentX = 50 + authorNameWidth + 2; // Start x position after author name
    const bylineFont = 10;

    // Draw Byline with wrapping
    bylineWords.forEach(word => {
      const lineWithWord = bylineLine + word + " ";
      const lineWidth = font.widthOfTextAtSize(lineWithWord, bylineFont);
      
      if (lineWidth < bylineMaxWidth) {
        bylineLine = lineWithWord; // Add the word to the current line
      } else {
        // If the line is too long, draw the current line and start a new one
        page.drawText(bylineLine.trim(), 
          { 
            x: currentX, 
            y: bylineCurrentY, 
            size: bylineFont,
            font, 
            color: rgb(0, 0, 0) 
          }
        );
    
        bylineCurrentY -= 20; // Move down for the next line
        bylineLine = word + " "; // Start a new line with the current word
      }
    });
    

    // Draw any remaining text in the last line
    if (bylineLine) {
      page.drawText(bylineLine.trim(),
        { 
          x: currentX, 
          y: bylineCurrentY,
          size: bylineFont,
          font, 
          color: rgb(0, 0, 0)
        }
      );
    }

    // Draw the copyright text on the last page
    page.drawText(copyrightText, 
      { 
        x: 50, 
        y: 40, 
        size: 8, 
        font, 
        color: rgb(0, 0, 0) 
      }
    );
  } catch (error) {
    console.error("Error building PDF:", error);
    return;
  }

  const pdfBytes = await pdfDoc.save();
  const blob = new Blob([pdfBytes], { type: "application/pdf" });
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.download = `${mainRecipeHeading} recipe.pdf`;
  link.click();
}

function attachPdfButtons() {
  const buttons = document.querySelectorAll(".download-recipe");
  buttons.forEach(button => button.addEventListener("click", generatePdfForRecipe));
}

function init() {
  attachPdfButtons();
}

const pdfGenerator = { init };

export default pdfGenerator;