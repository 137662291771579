// eslint-disable-next-line import/prefer-default-export
export const panelContentMismatchError = (numPanelSections, numMainSections) =>
  Error(
    `
    At least one left panel content section must be present.
    When including more than one left panel content sections, an equal number of main content sections must be supplied.
    A main content section that contains a footer component will be ignored in this calculation, as it will never have a corresponding left panel content section.
    ${numPanelSections} left panel content section${
      numPanelSections > 1 ? "s" : ""
    } found.
    ${numMainSections} main content section${
      numMainSections > 1 ? "s" : ""
    } found.`
  );
