const fieldTypesToWrap = ["[type='text']", "[type='tel']", "[type='email']", "[type='checkbox']", "[type='submit']", "[type='radio']", "select", "textarea"];
const fieldTypesToManageFocusOutline =  ["[type='text']", "[type='tel']", "[type='email']", "select"];

/**
 * Text inputs (including type=tel and type=email), checkboxes, radio buttons, selects, and textareas
 * require that additional markup and classes be added to the Sitecore generated form markup in order
 * for it to be properly styled.
 *
 * These styles have been broken out into Arrays contained in an Object to improve readability in the wrapFormFieldElement method.
 */
const textInputClasses = {
  containerClasses: ["pfs-form-control-text-input-container"],
  elementClasses: ["pfs-form-control-text-input"]
}

const checkboxClasses = {
 elementClasses: ["form-check-input", "pfs-form-check-input", "pfs-form-check-input-checkbox"],
 labelClasses: ["pfs-label", "form-check-label", "pfs-form-check-label", "pfs-form-check-label-checkbox"]
}

const radioClasses = {
 elementClasses: ["form-check-input", "pfs-form-check-input", "pfs-form-check-input-radio"],
 labelClasses: ["pfs-label", "form-check-label", "pfs-form-check-label", "pfs-form-check-label-radio"]
}

const selectClasses = {
 containerClasses: ["pfs-form-control-select-container"],
 elementClasses: ["pfs-select"]
}

const textareaClasses = {
 containerClasses: ["pfs-form-control-textarea-container"],
 elementClasses: ["form-control", "pfs-form-control", "pfs-form-control-textarea"]
}

const submitClasses = {
  containerClasses: ["pfs-form-control-submit-container"]
}

const invalidClass = "is-invalid";

const validClass = "is-valid";

/**
 * Create and return a caret element for select elements
 * @returns {Object} an HTML element
 */
function generateSelectCaretElement() {
  const selectCaret = document.createElement("i");
  const selectCaretClasses = ["fas", "fa-chevron-down", "pfs-select-caret"];

  selectCaretClasses.forEach(className => selectCaret.classList.add(className));

  return selectCaret;
}

/**
 * Add necessary markup and/or classes to the el for styling
 * @param {Object} el the element to manipulate
 */
function wrapFormFieldElement(el) {
  const wrapperElement = document.createElement("div");

  let validOrInvalidValueClass = "is-empty";

  if([...el.classList].includes("input-validation-error")){
    validOrInvalidValueClass = invalidClass;
  } else if(el.value !== "") {
    validOrInvalidValueClass = validClass;
  }

  /** Don't wrap elements inside of the .search-form */
  if (el.closest(".search-form") !== null) {
    return;
  }

  if (el.type === "text" || el.type === "tel" || el.type === "email") {
    const elStylesToApply = [...textInputClasses.elementClasses];
    const wrapperStylesToApply = [...textInputClasses.containerClasses, validOrInvalidValueClass];

    elStylesToApply.forEach(className => el.classList.add(className));
    wrapperStylesToApply.forEach(className => wrapperElement.classList.add(className));
    el.parentNode.insertBefore(wrapperElement, el);
    wrapperElement.appendChild(el);

  } else if (el.type === "checkbox") {
    const elLabel = el.closest("label");
    const elStylesToApply = [...checkboxClasses.elementClasses];
    const labelStylesToApply = [...checkboxClasses.labelClasses];

    elStylesToApply.forEach(className => el.classList.add(className));
    labelStylesToApply.forEach(className => elLabel.classList.add(className));
  } else if (el.type === "radio") {
    const elLabel = el.closest("label");
    const elStylesToApply = [...radioClasses.elementClasses];
    const labelStylesToApply = [...radioClasses.labelClasses];

    elStylesToApply.forEach(className => el.classList.add(className));
    labelStylesToApply.forEach(className => elLabel.classList.add(className));
  } else if (el.tagName === "SELECT") {
    const elStylesToApply = [...selectClasses.elementClasses];
    const wrapperStylesToApply = [...selectClasses.containerClasses, validOrInvalidValueClass];

    elStylesToApply.forEach(className => el.classList.add(className));
    wrapperStylesToApply.forEach(className => wrapperElement.classList.add(className));
    el.parentNode.insertBefore(wrapperElement, el);
    wrapperElement.appendChild(el);
    wrapperElement.appendChild(generateSelectCaretElement());

  } else if (el.tagName === "TEXTAREA") {
    const elStylesToApply = [...textareaClasses.elementClasses];
    const wrapperStylesToApply = [...textareaClasses.containerClasses, validOrInvalidValueClass];

    elStylesToApply.forEach(className => el.classList.add(className));
    wrapperStylesToApply.forEach(className => wrapperElement.classList.add(className));
    el.parentNode.insertBefore(wrapperElement, el);
    wrapperElement.appendChild(el);

  } else if (el.type === "submit") {
    const wrapperStylesToApply = [...submitClasses.containerClasses];

    wrapperStylesToApply.forEach(className => wrapperElement.classList.add(className));
    el.parentNode.insertBefore(wrapperElement, el);
    wrapperElement.appendChild(el);
  }
}

/**
 * Add blur and focus listeners to the el to toggle error classes as needed
 * @param {Object} el the element to listen to
 */
function manageFieldTypeFocusOutline(el) {
  const wrapperElement = el.tagName === "INPUT" ? el.closest(".pfs-form-control-text-input-container") : el.closest(".pfs-form-control-select-container")

  el.addEventListener("focus", e => {
    wrapperElement.classList.add("focused");
  });

  el.addEventListener("blur", e => {
    wrapperElement.classList.remove("focused");

    if ([...e.target.classList].includes("input-validation-error")) {
      wrapperElement.classList.remove(validClass);
      wrapperElement.classList.add(invalidClass);
    } else if ([...e.target.classList].includes("valid")) {
      wrapperElement.classList.add(validClass);
      wrapperElement.classList.remove(invalidClass);
    }
  });
}

/**
 * Find and wrap necessary form elements in the .main-content element
 * @param {Object} mainContentEl
 */
function wrapFields(mainContentEl) {
  fieldTypesToWrap.forEach(fieldTypeToWrap => {
    [...mainContentEl.querySelectorAll(fieldTypeToWrap)].forEach(field => wrapFormFieldElement(field));
  });
}

/**
 * Find inputs and select elements and add a custom focus behavior
 * @param {Object} mainContentEl
 */
function addFocusCustomBehaviors(mainContentEl) {
  fieldTypesToManageFocusOutline.forEach(fieldTypeToManageFocusOutline => {
    [...mainContentEl.querySelectorAll(fieldTypeToManageFocusOutline)].forEach(field => manageFieldTypeFocusOutline(field));
  });
}


function init() {
  const mainContentEl = document.querySelector(".main-content");
  if(mainContentEl) {
    // defining this on the global PFS object so it can be called by Sitecore JS
    window.PFS.intializeFormFields = function () {
      wrapFields(mainContentEl);
      addFocusCustomBehaviors(mainContentEl);
    }

    window.PFS.intializeFormFields();
  }
}

const formFields = {
  init
};

export default formFields;
