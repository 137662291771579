/** NOTE: this is for IE11 and may be removed when IE11 is no longer supported */
/* eslint-disable */
function init() {
  Element.prototype.remove = function () {
    this.parentElement.removeChild(this);
  }
  NodeList.prototype.remove = HTMLCollection.prototype.remove = function () {
    for (var i = this.length - 1; i >= 0; i--) {
      if (this[i] && this[i].parentElement) {
        this[i].parentElement.removeChild(this[i]);
      }
    }
  }
}

const remove = {
  init
};

export default remove;
/* eslint-enable */
