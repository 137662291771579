import $ from "jquery";
import debounce from "lodash/debounce";

function manageVisibleItems($list) {
  $list
    .find("li")
    .removeClass("d-none")
    .toArray()
    .forEach(() => {
      if ($list.offset().top < 0) {
        const $visibleItems = $list.find("li:visible");
        if ($visibleItems.length > 1) {
          $visibleItems.last().addClass("d-none");
        }
      }
    });
}

function onWindowResize() {
  manageVisibleItems($(".featured-article-list"));
}

const featuredArticleList = {
  init() {
    $(window).on("resize", debounce(onWindowResize, 100));
    onWindowResize();
  }
};

export default featuredArticleList;
