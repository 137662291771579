const VIEW_ALL_TEXT = "View All Brands";
const ACTIVE_CLASS = "active";

let initialToggleText;
let dropdownToggle;
let brandCards;
let currentBrandCategory;

/**
 * Compare the newCategory selected to each card's data-brand-category to find matching brands and hide the non-matches.
 * Display all brand cards if view all is selected
 * @param {string} newCategory the new brand category to search for
 */
function findMatches(newCategory) {
  brandCards.forEach(card => {
    card.style.display = card.dataset.brandCategory.includes(newCategory) || newCategory === VIEW_ALL_TEXT  ? "" : "none"; // eslint-disable-line
  });
}

/**
 * Find the active brand category and remove the active class if it exists
 */
function removeActiveCategory() {
  if (document.querySelector(".vertical-card-grid-dropdown-item.active")) {
    document.querySelector(".vertical-card-grid-dropdown-item.active").classList.remove(ACTIVE_CLASS);
  }
}

/**
 * Update the active category if it is different from the currently selected category
 * @param {Object} newCategory the category button element that was just clicked
 */
function updateActiveCategory(newCategory) {
  const newCategoryBrandCategory = newCategory.dataset.brandCategory;

  if (currentBrandCategory === newCategoryBrandCategory) {
    return;
  }

  removeActiveCategory();
  newCategory.classList.add(ACTIVE_CLASS);
  dropdownToggle.textContent = newCategoryBrandCategory === VIEW_ALL_TEXT ? initialToggleText : newCategoryBrandCategory;
  currentBrandCategory = newCategoryBrandCategory;
}

/**
 * Add click handlers to the category buttons in the dropdown
 * @param {Object} el the element to assign click handlers on
 */
function addClickHandlers(el) {
  el.addEventListener("click", function(e) { // eslint-disable-line
    findMatches(e.target.dataset.brandCategory);
    updateActiveCategory(e.target);
  });
}

function init() {
  if(document.querySelector(".vertical-card-grid") && document.querySelector(".vertical-card-grid-dropdown-toggle")) {
    dropdownToggle = document.querySelector(".vertical-card-grid-dropdown-toggle");
    initialToggleText = dropdownToggle.textContent;
    brandCards = [...document.querySelector(".vertical-card-grid-container").querySelectorAll("[data-brand-category]")];

    document.querySelectorAll(".vertical-card-grid-dropdown-item").forEach((item) => addClickHandlers(item));
    document.querySelector(".vertical-card-grid-dropdown-item").classList.add(ACTIVE_CLASS);
    currentBrandCategory = VIEW_ALL_TEXT;
  }
}

const brandFilter = {
  init
};

export default brandFilter;
