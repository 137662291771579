import $ from "jquery";
import debounce from "lodash/debounce";
import enquire from "enquire.js";
import { MQ_MOBILE_SMALL } from "../constants";

function getMaxHeight($el) {
  return parseInt($el.css("max-height").slice(0, -2), 10);
}

/**
 * Checks if device size matches the MQ_MOBILE_SMALL media query
 * @returns {boolean}
 */
function isMobile() {
  let isMobile = false;

  enquire
    .register(MQ_MOBILE_SMALL, {
      match() {
        isMobile = true;
      }
    });

  return isMobile;
}

function getPagedText({ text = "", characterCount = 245 }) {
  return text
    .split(" ")
    .reduce(
      (acc, cur) => {
        acc[acc[0].length < characterCount ? 0 : 1] += `${cur} `;
        return acc;
      },
      ["", ""]
    )
    .map(page => page.trim());
}

function setupPageChangers($card) {
  // Configure page changer
  $card
    .find(".page-changer")
    .on("click", () =>
      $card.find(".page-changer, .page-1, .page-2").toggleClass("d-none")
    )
    .eq(0)
    .removeClass("d-none");
}

function setPageHeights($card) {
  const $pages = $card.find(".page-1, .page-2");
  const height = $pages
    .toArray()
    .reduce((acc, cur) => Math.max($(cur).height(), acc), 0);
  $pages.height(height);
  $pages.filter(".page-2").addClass("d-none");
}

function setupEducationReadMore($card) {
  const pagedText = getPagedText({ text: $card.find(".card-content").text() });

  // Create page 1
  $card
    .addClass("read-more")
    .find(".card-label, .card-title, .card-content")
    .wrapAll("<div class='page-1'/>")
    .filter(".card-content")
    .text(`${pagedText[0]}...`);

  // Create page 2
  $card
    .find(".btn-container, .card-footer")
    .wrap("<div class='page-2' />")
    .parent()
    .prepend("<p class='card-content' />")
    .find(".card-content")
    .text(`...${pagedText[1]}`);

  setPageHeights($card);

  setupPageChangers($card);
}

function setupQuoteComponentReadMore($card) {
  /** this makes the character count somewhat smart based on the quote's font size */
  const characterCountMultiplier = isMobile() ? 6.5 : 4.5;
  const characterCount = parseInt($card.find(".card-content").css("font-size"), 10) * characterCountMultiplier;
  const metaText = $card.find(".card-meta").text();
  const quoteText = getPagedText({
    text: $card.find(".card-content").text(),
    characterCount
  });
  const footerHTML = $card.find(".card-footer").html();

  /** The quote component requires more complex markup than the education component, so template literals are used */
  const page1 = `
    <div class="page-1">
      <div class="card-body">
        <p class="card-meta">${metaText}</p>
        <p class="card-content">${quoteText[0]}...</p>
      </div>
    </div>`;

  const page2 = `
    <div class="page-2">
      <div class="card-body">
        <p class="card-content">...${quoteText[1]}</p>
      </div>
      <div class="card-footer">
        ${footerHTML}
      </div>
    </div>`;

  const pageChanger = `
    <span class="page-changer d-none"><i class="fa fa-arrow-circle-down read-more-arrows" aria-hidden="true"></i>Continue Reading</span>
    <span class="page-changer d-none"><i class="fa fa-arrow-circle-up read-more-arrows" aria-hidden="true"></i>Back to Top</span>
  `;

  const quoteMarkup = page1 + page2 + pageChanger;

  $card.addClass("read-more").html(quoteMarkup);

  setPageHeights($card);

  setupPageChangers($card);
}

function setupReadMore($card) {
  if ($card.hasClass("read-more")) {
    return;
  }

  if ($card.find(".quote-component-source").length > 0) {
    setupQuoteComponentReadMore($card);
  } else {
    setupEducationReadMore($card);
  }
}

function tearDownReadMore($card) {
  if (!$card.hasClass("read-more")) {
    return;
  }

  if ($card.find(".quote-component-source").length > 0) {
    const metaText = $card.find(".card-meta").text();
    const p1QuoteText = $card.find(".page-1 .card-content").text().replace("...", "");
    const p2QuoteText = $card.find(".page-2 .card-content").text().replace("...", "");
    const footerHTML = $card.find(".card-footer").html();

    const quoteMarkup = `
      <div class="card-body">
        <p class="card-meta">${metaText}</p>
        <p class="card-content">${p1QuoteText} ${p2QuoteText}</p>
      </div>
      <div class="card-footer">
        ${footerHTML}
      </div>
    `;

    $card.removeClass("read-more").html(quoteMarkup);
  } else {
    const $cardContent = $card.find(".card-content");
    const innerText = [
      $cardContent[0].innerText.slice(0, -3),
      $cardContent[1].innerText.slice(3)
    ];
    $cardContent[0].innerText = innerText.join(" ");
    $cardContent.eq(1).remove();
    $card.removeClass("read-more");
    $card
      .find(".card-label, .card-title, .card-content, .btn-container")
      .appendTo($card);
    $card
      .find(".page-changer")
      .off()
      .addClass("d-none");
    $card.find(".page-1, .page-2").remove();
  }
}

function configureReadMoreDisplay(component) {
  const $card = $(component).find(".card");
  tearDownReadMore($card);
  if ($card[0].scrollHeight > getMaxHeight($card)) {
    setupReadMore($card);
  }
}

const readMore = {
  init() {
    const configureAll = () =>
      $(".education, .quote-component")
        .toArray()
        .forEach(configureReadMoreDisplay);

    $(window).on("resize", debounce(configureAll, 100));
    configureAll();
  }
};

export default readMore;
