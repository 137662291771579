// Screen size
export const MIN_WIDTH_DESKTOP_LAYOUT = 1367;
export const MIN_WIDTH_TABLET_LAYOUT = 992;
export const MIN_WIDTH_TABLET_NAV = 577;
export const MAX_WIDTH_TABLET_LAYOUT = 1366;
export const MIN_WIDTH_MOBILE_LAYOUT = 568;
export const MAX_WIDTH_MOBILE_LAYOUT = 991;
export const MAX_WIDTH_MOBILE_SMALL_LAYOUT = 576;

export const MQ_DESKTOP = `screen 
  and (min-width:${MIN_WIDTH_DESKTOP_LAYOUT}px)`;

export const MQ_TABLET = `screen 
  and (min-width:${MIN_WIDTH_TABLET_LAYOUT}px)`;

export const MQ_MAX_WIDTH_TABLET = `screen
  and (max-width:${MAX_WIDTH_TABLET_LAYOUT}px)`;

export const MQ_TABLET_ONLY = `screen 
  and (min-width:${MIN_WIDTH_TABLET_LAYOUT}px) 
  and (max-width:${MAX_WIDTH_TABLET_LAYOUT}px)`;

export const MQ_TABLET_NAV = `screen 
  and (min-width:${MIN_WIDTH_TABLET_NAV}px) 
  and (max-width:${MAX_WIDTH_TABLET_LAYOUT}px)`;

export const MQ_MOBILE = `screen
  and (max-width:${MAX_WIDTH_MOBILE_LAYOUT}px)`;

export const MQ_MOBILE_WIDE_ONLY= `screen
  and (min-width:${MIN_WIDTH_MOBILE_LAYOUT}px)
  and (max-width:${MAX_WIDTH_MOBILE_LAYOUT}px)`;

export const MQ_MOBILE_SMALL = `screen
  and (max-width:${MAX_WIDTH_MOBILE_SMALL_LAYOUT}px)`;

// Display values
export const BRAND_BRIGHT_RED = "#B5121B";

// Scroll
export const SCROLL_TYPE_STICKY_LEFT_PANEL = "stickyleftpanel";
export const SCROLL_TYPE_SCROLLING_LEFT_PANEL = "scrollingleftpanel";
