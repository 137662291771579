import ally from "ally.js";

// TODO - trap focus on search
//
/**
 * Home: 
 * - header
 * - left panel content
 * - slide
 * - left panel content
 * - slide, etc until last slide.
 * - left panel footer
 * - footer
 *  on tab press
 *  is current focus last item in header?
 *  then go to first focusable item in first left pane
 *  then go to first focuasable item in slide (maybe down arrow)
 *  on tab into next slide, go to first focusable item in left pane OR next slide
 *  until no more slides, then go to left rail buttons, then footer
 */

const zones = {
  HEADER: 'header',
  LEFT_PANE: 'leftpane',
  SLIDE: 'slide',
  LEFT_FOOTER: 'leftfooter',
  SLIDE_FOOTER: 'slidefooter'
};

let leftPanels;
let fpsSlides;
let currentZone;

window.ally = ally;

function onTab (e) {
  if (e.key !== 'Tab') return;
}

function init() {

  //leftPanelContent = document.querySelectorAll('.left-rail .left-panel-content');
}

const keyboardManager = { init };

export default keyboardManager;
