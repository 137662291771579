import $ from "jquery";
import debounce from "lodash/debounce";
import { MAX_WIDTH_MOBILE_LAYOUT } from "../constants";

let $btn;
let $window = $(window);

function initializeScrollToTop() {
  $btn.on("click", () => {
    const scrollDest = $window.innerWidth() <= MAX_WIDTH_MOBILE_LAYOUT
      ? $window.innerHeight()
      : 0;
    $("html, body").animate({ scrollTop: scrollDest }, "slow");
    return false;
  });

  $window.scroll(debounce(() => {
    const scrollOffset = $window.innerWidth() <= MAX_WIDTH_MOBILE_LAYOUT
      ? $window.innerHeight() + 200
      : 200;
    if($window.scrollTop() > scrollOffset) {
      $btn.addClass('visible')
    } else {
      $btn.removeClass('visible');
    }
  }, 200));
}

const scrollToTop = {
  init() {
    $btn = $(".scroll-to-top")
    if($btn) {
      initializeScrollToTop();
    }
  }
}

export default scrollToTop;
