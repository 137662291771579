import { MAX_WIDTH_MOBILE_SMALL_LAYOUT } from "../constants";

/**
 * Configures elements to display the appropriate image based on pixel
 * density and viewport width. This function sets the image uri as the
 * value of the `src` attribute in the case of `IMG` elements, otherwise
 * the uri is set as an inline `background-image` style. Uris are set as
 * data attributes on elements as follows:
 * `data-img-src`: The low resolution image.
 * `data-img-src-2x`: The high resolution image, used for dpr > 1.
 * `data-img-small`: A version of the image for small viewports (assumed to be 2x as supported devices have dpr > 1).
 * @param {NodeList} nodes The elements to configure.
 * @param {boolean} useHiRes Whether to use the high resolution image.
 */
function setImages(nodes, useHiRes) {
  nodes.forEach(node => {
    let uri = node.dataset.imgSrc;
    uri = useHiRes ? (node.dataset["imgSrc-2x"] || uri) : uri;
    uri = window.innerWidth <= MAX_WIDTH_MOBILE_SMALL_LAYOUT
      ? (node.dataset.imgSrcSmall || uri) : uri;

    if(node.nodeName.toLowerCase() === "img") {
      node.src = uri;
    } else {
      node.style.backgroundImage = `url(${uri})`;
    }
  });
}

function init() {
  const useHiRes = window.devicePixelRatio > 1;
  setImages(document.querySelectorAll("[data-img-dynamic]"), useHiRes);
}

const imageManager = { init };

export default imageManager;
