/** NOTE: this is for IE11 and may be removed when IE11 is no longer supported */
/* eslint-disable */
function init() {
  if (!Element.prototype.matches) {
    Element.prototype.matches =
      Element.prototype.msMatchesSelector ||
      Element.prototype.webkitMatchesSelector;
  }

  if (!Element.prototype.closest) {
    Element.prototype.closest = function(s) {
      let el = this;

      do {
        if (el.matches(s)) return el;

        el = el.parentElement || el.parentNode;

      } while (el !== null && el.nodeType === 1);
      return null;
    };
  }
}

const closest = {
  init
};

export default closest;
/* eslint-enable */
