/**
 * Provide a standard loader animation that can be utilized by other JS files
 */
function show() {
  const loader = document.createElement("div");

  loader.classList.add("loader");
  loader.innerHTML = `<div class="spinner"></div>`;
  document.querySelector("body").appendChild(loader);
}

function hide() {
  document.querySelector(".loader").remove();
}

const loaderAnimation = { show, hide };

export default loaderAnimation;
