import $ from "jquery";
import enquire from "enquire.js";
import debounce from "lodash/debounce";
import ally from "ally.js";
import {
  MQ_DESKTOP,
  MQ_MOBILE_SMALL,
  MIN_WIDTH_TABLET_LAYOUT
} from "../constants";
import { SEARCH_REQUEST_COMPLETE } from "../events";

let $navCollapse;
let $searchModal;
let searchModalFocusTrap;

// Displays the nav dropdown on nav-item hover.
// Used in desktop view only (< 1366px).
function navDropdownHoverListener(e) {
  const $dropdown = $(e.target).closest(".dropdown");
  const $menu = $(".navbar-nav .dropdown-menu", $dropdown);

  setTimeout(
    () => {
      const shouldOpen = e.type !== "click" && $dropdown.is(":hover");
      $menu.toggleClass("show", shouldOpen);
      $dropdown.toggleClass("show", shouldOpen);
      $(".navbar-nav [data-toggle='dropdown']", $dropdown).attr(
        "aria-expanded",
        shouldOpen
      );
    },
    e.type === "mouseleave" ? 300 : 0
  );
}

// Handles adding and removing event listeners that facilitate showing
// the nav dropdown on hover.
function manageNavDropdownHoverListeners(register = true, listener) {
  const method = register ? "on" : "off";
  $("body")
    [method]("mouseenter mouseleave", ".navbar-nav .dropdown", listener)
    [method]("click", ".navbar-nav .dropdown-menu a", listener);
}

/**
 * Closes the collapse immediately (no animation)
 * @param {jQuery} $collapse jQuery reference to bootstrap collapse.
 */
function closeCollapse($collapse) {
  $collapse.collapse("hide").removeClass("show collapsing");
  $collapse.css("opacity", 0);
  setTimeout((() => $collapse.css("opacity", 1)), 400);
}

function manageSearch(isDesktop) {
  const searchTrigger = document.querySelector('.nav-link.search-trigger');
  searchTrigger.tabIndex = isDesktop ? 0 : -1;
  searchTrigger.setAttribute('aria-hidden', isDesktop ? 'false' : 'true');
}

/**
 * Displays the search modal overlay and prevents the underlying page from scrolling.
 * @param {jQuery} $modal jQuery reference to the modal element.
 */
function displaySearchModal($modal) {
  $("body").addClass("no-scroll");
  $modal.css("display", "flex");
  searchModalFocusTrap = ally.maintain.tabFocus({
    context: '#search-modal',
  });
}

/**
 * Hides the search modal overlay and restores scrolling to the underlying page.
 * @param {jQuery} $modal jQuery reference to the modal element.
 */
function hideSearchModal($modal) {
  $("body").removeClass("no-scroll");
  $modal.hide();
  if(searchModalFocusTrap) {
    searchModalFocusTrap.disengage();
    document.querySelector('.nav-link.search-trigger').focus();
  }
}

function initializeSearchModal($modal) {
  $modal.find(".search-close").click(() => {
    hideSearchModal($modal);
  });

  document.addEventListener(SEARCH_REQUEST_COMPLETE, () => $modal.hide());

  $(".navbar-search, .search-trigger").click(() => {
    if (window.innerWidth >= MIN_WIDTH_TABLET_LAYOUT) {
      displaySearchModal($modal);
      $modal.find("input[type=search]").focus();
      $(document).on('keydown' ,function(event) {
        if (event.key == "Escape") { 
          hideSearchModal($modal);
          $(this).off('keydown');
        }
      })
    }
  });
}

function initializeSearchForms() {
  // add a submit listener and clear out input values
  $("#search-modal .search-form, .pfs-header .search-form")
    .submit(e => {
      if($(e.currentTarget).find("input").val().trim().length === 0) {
        e.preventDefault();
      }
    }).find("input").val("");
}

/**
 * This function exists to address a bug (549) where when resizing the browser window
 * from tablet to desktop layout, the .navbar-head element is not left-justified, but
 * but rather floats over the main-content area. There is likely a cleaner CSS-only
 * approach; this method should be deleted if/when that approach is determined.
 */
function onResizeIE() {
  if (navigator.userAgent.indexOf("Trident") > -1) {
    const navbarHead = document.querySelector(".navbar-head");
    window.addEventListener(
      "resize",
      debounce(() => {
        navbarHead.style.flexShrink = parseInt(navbarHead.style.flexShrink, 10) === 1 ? 0 : 1;
      }, 50)
    );
  };
}

function expandNavMenuOption (e) {
  const $menu = $(".navbar-nav .dropdown-menu");
  const $firstMenuItem = $(e.target).siblings(".dropdown-menu").find('a')[0]
  const $dropdown = $(e.target).closest(".dropdown");

  $menu.addClass("show");
  $dropdown.addClass("show");
  e.target.setAttribute('aria-expanded', "true");
  $firstMenuItem.focus();
}

function collapseNavMenuOption (e) {
  const $dropdown = $(e.target).closest(".dropdown");
  const $menu = $(".navbar-nav .dropdown-menu");
  const $expandMenuOptionElements = $(".nav-link-expand-option")

  $menu.removeClass("show");
  $dropdown.removeClass("show");
  $expandMenuOptionElements.each(function(_, expandMenuOption) {
    expandMenuOption.setAttribute('aria-expanded', 'false');
  })
}

function initializeNavMenuOption () {
  const $dropdownMenus = $(".dropdown-menu");

  $('.nav-link-expand-option').on('keydown', function(e) {
    if (e.key === "Enter") {
      expandNavMenuOption(e);
    }
  })

  $dropdownMenus.each(function (_, dropdown) {
    $(dropdown).on("keydown", function(e) {
      if (e.keyCode === 9 && e.target === dropdown.lastElementChild.lastElementChild ) {
        console.log('tab')
        collapseNavMenuOption(e);
      }
    })
  })
}

const header = {
  init() {
    $navCollapse = $(".navbar-collapse");
    $searchModal = $("#search-modal");


    initializeSearchModal($searchModal);
    initializeSearchForms();
    onResizeIE();
    initializeNavMenuOption();

    enquire.register(MQ_DESKTOP, {
      match() {
        closeCollapse($navCollapse);
        manageNavDropdownHoverListeners(true, navDropdownHoverListener);
        manageSearch(true);
      },
      unmatch() {
        closeCollapse($navCollapse);
        manageNavDropdownHoverListeners(false, navDropdownHoverListener);
        manageSearch(false);
      }
    }).register(MQ_MOBILE_SMALL, {
      match() {
        hideSearchModal($searchModal);
      }
    });
  }
};

export default header;
