import { SEARCH_REQUEST_COMPLETE } from "../events";

const superscripts = ['®', '™', '©'];

function applySuperscripts(container) {
  let treeWalker = document.createTreeWalker(container, NodeFilter.SHOW_TEXT);
  while (treeWalker.nextNode()) {
    const node = treeWalker.currentNode;
    if(
      superscripts.some(i => node.textContent.includes(i) 
      && node.nodeType === 3 
      && node.nodeValue 
      && node.parentElement.tagName.toLowerCase() !== 'sup')
    ) {
        const superscripted = superscripts.reduce((acc, cur) => {
          const re = new RegExp(`${cur}`, "g");
          return acc.replace(re, `<sup class="pfg-superscript">${cur}</sup>`)
        }, node.parentElement.innerHTML);
        setTimeout((() => node.parentElement.innerHTML = superscripted), 1);
    }
  }
}

function onSearchResultsRendered() {
  document.querySelectorAll('.listing-item').forEach(item => {
    if(item.classList.contains('pfs-superscripted')) return;
    applySuperscripts(item);
    item.classList.add('pfs-superscripted');
  });
}

function init() {
  document.addEventListener(SEARCH_REQUEST_COMPLETE, onSearchResultsRendered);
  applySuperscripts(document.body);
}

export default { init };
