function init () {
  addAriaRoleToRequiredMessages()
}

function addAriaRoleToRequiredMessages() {
  const formElement = document.querySelector(".main-content form");

  if(!formElement) {
    return;
  }

  const config = { attributes: true, childList: true, subtree: true };
  const callback = (mutationList, observer) => {
    for (const mutation of mutationList) {
      if (mutation.type === "childList") {
        const fieldValidationRequiredLabel = mutation.target;
        if (fieldValidationRequiredLabel.className != "search-label"){
          fieldValidationRequiredLabel.setAttribute("role", "alert");        
        } 
      }
    }
  };

  const observer = new MutationObserver(callback);
  observer.observe(formElement, config);
}

const formFieldsIsRequired = {
  init
};

export default formFieldsIsRequired;
