import debounce from "lodash/debounce";

/**
 * Check if this is an interior page
 * @returns {boolean}
 */
function isInteriorPage() {
  return document.querySelectorAll(".pfs-interior").length > 0;
}

/**
 * Get the height of the fixed nav
 * @returns {Number}
 */
function getNavHeight() {
  return `${document.querySelector(".fixed-top").offsetHeight}px`;
}

/**
 * Get child elements with IDs in the .main-content that are not form elements/children, accordions, children of accordions, or dropdowns
 * @returns {Array}
 */
function getMainContentElementsWithID() {
  return [...document.querySelector(".main-content").querySelectorAll("[id]")]
            .filter(el => el.classList.length === 0 &&
            el.tagName !== "FORM" &&
            el.closest("form") === null ||
            [...el.classList].includes("accordion") === -1 &&
            el.dataset.toggle === null &&
            el.closest(".accordion") === null); // eslint-disable-line
}

/**
 * Set el's negative margin equal to the nav height
 * @param {Object} el the element to set the height and margin top on
 * @returns {Object}
 */
function setDummyElementStyles(el) {
  const dummyElement = el;
  dummyElement.style.marginTop = `-${getNavHeight()}`;
  dummyElement.style.height = `${getNavHeight()}`;
  return dummyElement;
}

/**
 * Create an element the same height as the nav with a negative margin equal to the nav height
 * @param {Object} el the element with an ID to create a dummy anchor for
 * @returns {Object}
 */
function createDummyAnchor(el) {
  const dummyAnchor = document.createElement("div");
  dummyAnchor.id = el.id;
  return setDummyElementStyles(dummyAnchor);
}

/**
 * Insert a dummy element with the el parameter's ID before the el and remove the el's ID
 * @param {Object} el the element where the dummy element will be inserted before
 */
function insertDummyAnchor(el) {
  el.parentNode.insertBefore(createDummyAnchor(el), el);
  el.removeAttribute("id");
}

/**
 * Detect when the window is resized and update the dummy elements' styles to account for changes in the nav height
 */
function onWindowResize() {
  getMainContentElementsWithID().forEach(el => {
    setDummyElementStyles(el);
  });
}

function init() {
  if (isInteriorPage()) {
    getMainContentElementsWithID().forEach(el => {
      insertDummyAnchor(el);
    });

    window.addEventListener("resize", debounce(onWindowResize, 100));
  }
}

const anchorTags = {
  init,
  getNavHeight
};

export default anchorTags;
