import $ from "jquery";
import enquire from "enquire.js";
import { MQ_MAX_WIDTH_TABLET } from "../constants";

/**
 * Comply with requirement form image caption to be
 * directory below image on mobile.
 * @param  hero The hero-carousel-item-2-column element.
 */
function positionCaptionForMobile(hero) {
  $(hero)
    .find(".caption")
    .insertAfter($(hero).find("img"));
}

/**
 * Comply with requirement form image caption to be below
 * image and paragraph on desktop.
 * @param  hero The hero-carousel-item-2-column element.
 */
function positionCaptionForDesktop(hero) {
  $(hero)
    .find(".caption")
    .insertAfter($(hero).find(".hero-2-column"));
}

function init() {
  const $heroCarouselItem2Column = $(".hero-carousel-item-2-column");
  enquire.register(MQ_MAX_WIDTH_TABLET, {
    match() {
      $heroCarouselItem2Column.toArray().forEach(positionCaptionForMobile);
    },
    unmatch() {
      $heroCarouselItem2Column.toArray().forEach(positionCaptionForDesktop);
    }
  });
}

export default { init };
