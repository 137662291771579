import $ from "jquery";
import { MAX_WIDTH_MOBILE_SMALL_LAYOUT } from "../constants";

let currentPageYOffset = Math.round(window.pageYOffset);

/**
 * Detects if the client is a mobile device.
 * Regex borrowed from http://detectmobilebrowsers.com/
 * @returns {boolean}
 */
function isMobile() {
  const regexes = [
    /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i,
    /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i
  ];
  const ua = navigator.userAgent || navigator.vendor || window.opera;
  return regexes[0].test(ua) || regexes[1].test(ua.substr(0,4));
}

/**
 * Generates HTML for the arrow used on the bottom of
 * full-page-scroll slides and the left panel on interior
 * pages (in narrow viewports).
 * @returns {string} HTML for the full page arrow.
 */
function getFullPageArrowMarkup() {
  return `
    <!-- THIS MARKUP IS ADDED DYNAMICALLY -->
    <button aria-label="Scroll down to next section" class="full-page-arrow">
      <i class="fas fa-angle-down"></i>
    </button>
    <!-- END DYNAMIC MARKUP -->
  `;
}

/**
 * Adds the down arrow to the left panel (only visible
 * in narrow viewports) that, when clicked, animates
 * the main content area into view.
 */
function initInteriorPageScroll() {
  const $mainContent = $(".main-content");
  const $header = $(".pfs-header");
  $(".left-panel")
    .append(getFullPageArrowMarkup())
    .find(".full-page-arrow")
    .click(() => {
      $("html, body").animate({
        scrollTop: $mainContent.offset().top - $header.height()
      });
    });
}

/**
 * Shows or hides the rotate screen mask depending on the media query
 * matches value.
 * @param {MediaQueryList|MediaQueryListEvent} mq
 */
function manageScreenOrientationMask(mq) {
  if (Math.min(window.innerWidth, window.innerHeight) <= MAX_WIDTH_MOBILE_SMALL_LAYOUT && isMobile()) {
    const pageHtmlTag = document.getElementsByTagName("html");
    const body = document.getElementsByTagName("body");
    const mask = document.getElementById("viewport-mask");
    const scrollChangeThreshold = 150;

    /** Since the device has already rotated at this point, the pageYOffset will be coming from the landscape orientation.
     * By using scrollChangeThreshold, we can prevent small changes in scroll position that occur when a content reflow
     * is triggered, which seemed to change the window's pageYOffset in a minor, but noticeable way.
     * The scrollChangeThreshold value ensures the currentPageYOffset is only updated when the user scrolled
     * a meaningful amount up or down prior to rotating the device to landscape.
     * Written as an if statement rather than a ternary for readability's sake.
     */
    if (currentPageYOffset === 0) {
      currentPageYOffset = Math.round(window.pageYOffset);
    } else if (mq.matches === true && (currentPageYOffset > Math.round(window.pageYOffset) + scrollChangeThreshold || currentPageYOffset < Math.round(window.pageYOffset) - scrollChangeThreshold)) {
      currentPageYOffset = Math.round(window.pageYOffset);
    }

    if (mask) {
      mask.classList[mq.matches === true ? "add" : "remove"]("visible");
      pageHtmlTag[0].classList[mq.matches === true ? "add" : "remove"]("viewport-mask-is-visible");
      body[0].classList[mq.matches === true ? "add" : "remove"]("viewport-mask-is-visible");
      /**
       * Since the mask is position: fixed, the window needs scrolled to the top in order for it to be in view.
       * If the mask should not be displayed, return to the original-ish scroll position
       */
      window.scrollTo(0, mq.matches === true ? 0 : currentPageYOffset);
    } else {
      console.warn("An element with id `viewport-mask` was not found on this page.");
    }
  }
}

/**
 * Initializes orientation media query listner.
 */
function initOrientationListener() {
  if (typeof window.matchMedia !== "function") {
    return;
  }
  const mql = window.matchMedia("(orientation: landscape)");
  mql.addListener(manageScreenOrientationMask);
  manageScreenOrientationMask(mql);
}

function init() {
  initOrientationListener();
  if ($(".pfs-interior").length > 0) {
    initInteriorPageScroll();
  }
}

export default {
  isMobile,
  getFullPageArrowMarkup,
  init
};
