/** Note: Prettier is overly concerned with this mock data structure  */
/* eslint-disable */
const dynamicListingSuccess = [
  {
      "Id":"{B592B8A2-61B1-4A75-AB14-D3F2884511A5}",
      "Name":"How To Attract Influencers",
      "Tag":"",
      "Title":"How To Attract Influencers",
      "Summary":"Everyone is buzzing about how to get so-called influencers, the bloggers and Instagrammers that everyone looks to for news and recommendations, to talk about their food. Here are some of our tips to attract them.",
      "LinkText":"Read this Article",
      "Image":"",
      "LinkUrl":"",
      "MetaData":[
        {
            "Guid":"06bcecf1-b31d-4c10-83dc-b5578f4c7e64",
            "IsGlobalNullId":false,
            "IsNull":false
        },
        {
            "Guid":"d737e704-5cdf-4c11-a1e1-96ae14748165",
            "IsGlobalNullId":false,
            "IsNull":false
        }
      ],
      "MetaDataValuesString":"Driving Door Swings,Savory Insights",
      "ID":{
        "Guid":"b592b8a2-61b1-4a75-ab14-d3f2884511a5",
        "IsGlobalNullId":false,
        "IsNull":false
      },
      "DateCreated":"\/Date(-62135578800000)\/"
  },
  {
      "Id":"{35D541E4-355D-4FF1-B491-AE5A51DB705D}",
      "Name":"Spring Food Beverage Pairings",
      "Tag":"Savory Insights",
      "Title":"Spring Food and Beverage Pairings",
      "Summary":"Get ideas for carefully crafted bar nibbles highlighting the season’s freshest produce, meats, and seafood paired with an array of wines, cocktails, and craft beer as a crowd-pleasing spring menu.",
      "LinkText":"Read this Article",
      "Image":"",
      "LinkUrl":"",
      "MetaData":[
        {
            "Guid":"af501755-5ffa-42fb-94be-b6e81e3c4806",
            "IsGlobalNullId":false,
            "IsNull":false
        },
        {
            "Guid":"bee32e95-9e52-466b-b2f5-7f65e611e360",
            "IsGlobalNullId":false,
            "IsNull":false
        },
        {
            "Guid":"d737e704-5cdf-4c11-a1e1-96ae14748165",
            "IsGlobalNullId":false,
            "IsNull":false
        }
      ],
      "MetaDataValuesString":"Trends and Inspiration,Kendra Bailey Morris,Savory Insights",
      "ID":{
        "Guid":"35d541e4-355d-4ff1-b491-ae5a51db705d",
        "IsGlobalNullId":false,
        "IsNull":false
      },
      "DateCreated":"\/Date(-62135578800000)\/"
  },
  {
      "Id":"{356D5CFF-01D9-407D-9B26-2A7D8777EBC1}",
      "Name":"Smart Sponsorships for Food Businesses",
      "Tag":"Savory Insights",
      "Title":"Smart Sponsorships for Food Businesses",
      "Summary":"Increasingly, restaurants and food brands are finding it necessary to shift marketing dollars away from advertising and toward activations such as event sponsorship. Learn how to make it work for your operation.",
      "LinkText":"Read this Article",
      "Image":"",
      "LinkUrl":"",
      "MetaData":[
        {
            "Guid":"06bcecf1-b31d-4c10-83dc-b5578f4c7e64",
            "IsGlobalNullId":false,
            "IsNull":false
        },
        {
            "Guid":"d737e704-5cdf-4c11-a1e1-96ae14748165",
            "IsGlobalNullId":false,
            "IsNull":false
        }
      ],
      "MetaDataValuesString":"Driving Door Swings,Savory Insights",
      "ID":{
        "Guid":"356d5cff-01d9-407d-9b26-2a7d8777ebc1",
        "IsGlobalNullId":false,
        "IsNull":false
      },
      "DateCreated":"\/Date(-62135578800000)\/"
  }
];
/* eslint-enable */

export default dynamicListingSuccess;