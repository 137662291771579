import $ from "jquery";

let $locationIndicatorDropdown;

/**
 * Add event handlers to toggle the location-selector-visible on the .navbar.
 * @param {jQuery} $locationDropdown The location indicator dropdown element
 */
function initializeLocationIndicatorDropdown($locationDropdown) {
  const $navBar = $(".navbar");
  $locationDropdown.on("show.bs.dropdown", () => {
    $navBar.addClass("location-selector-visible");
    //closeCollapse($navMenu);
  }).on("hide.bs.dropdown", () => {
    $navBar.removeClass("location-selector-visible");
  });
}

const locationSelector = {
  init() {
    $locationIndicatorDropdown = $(".location-selector .dropdown");
    initializeLocationIndicatorDropdown($locationIndicatorDropdown);
  }
};

export default locationSelector;
